/*
 * currently it is only used an the landing page
 */
.o-context-container {
  max-width: 1200px;
  margin: auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 768px) {
  .o-context-container {
    padding-left: 56px;
    padding-right: 56px;
  }
}

@media (min-width: 1680px) {
  .o-context-container--full-size {
    max-width: initial;
    margin: 0;
    padding: 0;
  }
}
