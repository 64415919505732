.o-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
}

.o-table__cell {
  margin: 0;
  padding: 16px 8px;
}

.o-table__cell--header {
  padding: 0 8px;
}

.o-table__cell--header:first-child {
  padding: 0 16px;
}

.o-table__cell--border-left {
  border-left: 1px solid var(--grey-90);
}

.o-table__cell--border-bottom {
  border-bottom: 1px solid var(--grey-90);
}

.o-table__cell--align-right {
  text-align: right;
}

.o-table__head--no-header {
  display: none;
}

.o-table__row {
  box-sizing: border-box;
  border: 1px solid var(--grey-90);
  border-radius: 3px 3px 0 0;
  color: var(--grey-40);
}

.o-table__row--clickable {
  cursor: pointer;
}

.o-table__row--min-padding .o-table__cell {
  padding: 8px 16px;
}

.o-table__row .o-table__cell:first-child {
  padding-left: 16px;
}

.o-table__row .o-table__cell:last-child {
  padding-right: 16px;
}

.o-table__expanded-row {
  border-radius: 3px;
}

.o-table__header {
  display: flex;
  align-items: center;
  color: var(--grey-30);
}

.o-table__spacer {
  display: block;
  height: 10px;
  border: none;
}

.is-expanded.o-table__row {
  display: none;
}

.o-table__row:first-child + .o-table__spacer {
  display: none;
}

.o-table__spacer + .is-expanded + .o-table__spacer {
  display: none;
}

.o-table .o-table__row:nth-child(even):not(.o-table__expanded-row) {
  background-color: var(--grey-98);
}

.o-table.o-table--no-color-switch .o-table__row:nth-child(even) {
  background-color: #fff;
}

@media (max-width: 1024px) {
  .o-table__cell {
    font-size: var(--font-size-small);
    max-width: 100%;
    padding: 3px 3px 3px 16px;
    box-sizing: border-box;
  }

  .o-table__head--collapsible {
    display: none;
  }
}

@media (max-width: 768px) {
  .o-table__row--margin-bottom {
    display: block;
    margin-bottom: 8px;
  }
}
