.c-registration__legal {
  color: var(--grey-40);
}

.c-registration__legal a {
  text-decoration: none;
  color: var(--mid-blue);
}

.c-registration__legal a:hover {
  color: var(--sky-blue);
}

.c-registration__legal a:active {
  color: var(--navy-blue);
}

.c-registration__points-list {
  color: var(--grey-40);
  margin-bottom: 32px;
}

.c-registration__point {
  margin-bottom: 16px;
}

.c-registration__point-icon {
  margin-right: 16px;
}

/* This houses the form field for spambot detection honeypot, must be hidden */
.c-registration__sb-detect {
  display: none;
}
