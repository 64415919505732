.s-form-group {
  margin-bottom: 24px;
}

.s-form-group.error {
  color: var(--pink);
}

.s-form-group--with-columns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .s-form-group--with-columns {
    flex-direction: row;
  }
}

.s-form-group--full-screen .s-form-group__column:last-child {
  margin-right: 10%;
}

.s-form-group__column {
  margin-bottom: 8px;
  width: 100%;
}

@media (min-width: 1024px) {
  .s-form-group__column {
    margin-right: 32px;
    margin-bottom: 24px;
  }
}

.s-form-group__column:last-child {
  margin-right: 0;
}

@media (min-width: 1024px) {
  .s-form-group__column--fluid-40 {
    width: 40%;
  }
}

.s-form-group__column--with-subcolumns {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .s-form-group__column--with-subcolumns {
    flex-direction: row;
    justify-content: space-between;
  }
}

.s-form-group__sub-column {
  margin-bottom: 8px;
  width: 100%;
}

@media (min-width: 1024px) {
  .s-form-group__sub-column {
    margin-right: 24px;
    margin-bottom: 0;
  }
}

.s-form-group__sub-column:last-child {
  margin-right: 0;
}
