.c-send-entry-modal__institution-selector {
  max-height: 400px;
  padding-right: 16px;
  overflow: auto;
}

@media (min-width: 768px) {
  .c-send-entry-modal__institution-selector {
    max-height: 500px;
  }
}

.c-send-entry-modal__institution-selector-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--grey-90);
  padding: 16px 0;
}

.c-send-entry-modal__institution-selector-item:first-child {
  border-top: none;
}

.c-send-entry-modal__institution-selector-item:last-child {
  padding: 16px 0 8px;
}

.c-send-entry-modal__institution {
  color: var(--grey-40);
}

.c-send-entry-modal__share-disable {
  position: absolute;
  right: 0;
  width: 80px;
  height: 24px;
}
