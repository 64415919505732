.o-container,
.o-container--fluid {
  margin-left: var(--grid-margin);
  margin-right: var(--grid-margin);
  overflow-x: hidden;
}

@media (min-width: 1440px) {
  .o-container {
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;
  }

  .o-container--fluid {
    margin-left: var(--grid-margin);
    margin-right: var(--grid-margin);
  }
}
