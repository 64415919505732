html,
body {
  font-family: var(--font-family);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

button,
input {
  font-family: inherit;
}

nav {
  font-weight: 600;
  letter-spacing: -0.01em;
  color: var(--grey-900);
}

p {
  font-weight: 400;
  letter-spacing: -0.01em;
  color: var(--grey-800);
}

header {
  width: 100%;
  background-color: white;
  z-index: 100;
}
