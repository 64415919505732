.s-tooltip {
  position: relative;
  border: 1px solid var(--grey-85);
  border-radius: 3px;
  padding: 24px 16px 8px;
  display: none;
  color: var(--grey-60);
  background-color: white;
  z-index: 20;
}

.s-tooltip::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  margin-left: auto;
  margin-right: auto;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 1px solid var(--grey-85);
  border-top: none;
  border-left: none;
}

[data-popper-placement='bottom'].s-tooltip::after {
  top: 0;
  transform: translate(-50%, -50%) rotate(225deg);
}
