.leaflet-map .leaflet-popup-content {
  margin: 0;
  padding: 0;
  padding-top: 16px;
}

.leaflet-map .leaflet-popup-content-wrapper {
  padding: 0;
}

.leaflet-map .leaflet-map-popup__button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: white;
}

.leaflet-map .leaflet-popup-tip {
  background: #0067b2;
}

.leaflet-map .leaflet-popup-content {
  min-width: max-content;
}
