.c-medical-record-entries__header {
  color: var(--navy-blue);
}

.c-medical-record-entries__container {
  position: relative;
}

.c-medical-record-entries__search-field {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 8px 0 8px 24px;
  border: none;
  border-bottom: 1px solid var(--mid-blue);
  border-radius: 0;
  box-sizing: border-box;
  letter-spacing: -0.01em;
}

.c-medical-record-entries__search-field:focus {
  outline: none;
}

.c-medical-record-entries__search-field::placeholder {
  color: var(--grey-45);
  opacity: 1;
}

.c-medical-record-entries__search-field-icon {
  position: absolute;
  top: 8px;
  color: var(--mid-blue);
}

.c-medical-record-entries__category-name {
  display: flex;
  align-items: flex-start;
  font-weight: 600;
  color: var(--navy-blue);
}

.c-medical-record-entries__category-name--large {
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  color: var(--navy-blue);
}

.c-medical-record-entries__category-name--clickable {
  color: var(--mid-blue);
}

.c-medical-record-entries__category-name--clickable:hover {
  color: var(--sky-blue);
}

.c-medical-record-entries__category-description-container {
  margin-top: 0;
}

.c-medical-record-entries__category-icon {
  margin-right: 16px;
  font-size: var(--font-size-default);
}

.c-medical-record-entries__category-icon--large {
  font-size: var(--font-size-medium);
}

.c-medical-record-entries__category-value {
  color: var(--grey-30);
}

.c-medical-record-entries__share-status-icon {
  margin-right: 4px;
  font-size: var(--font-size-default);
}

.c-medical-record-entries__icon {
  font-size: var(--font-size-default);
}

.c-medical-record-entries__action_icons {
  width: 38px;
  min-width: 38px;
  text-align: center;
  padding: 0;
}

.c-medical-record-entries__accept-reject {
  padding: 0;
  font-weight: normal;
}

.c-medical-record-entries__expanded-row-container {
  position: relative;
  padding: 48px 72px 48px 48px;
}

.c-medical-record-entries__expanded-row-container .c-medical-record-entries__category-name {
  display: block;
}

.c-medical-record-entries__expanded-row-container .c-medical-record-entries__category-icon {
  margin-right: 0;
  font-size: var(--font-size-medium);
}

.c-medical-record-entries__expanded-row-action-icons {
  position: absolute;
  top: 16px;
  right: 0;
}

.c-medical-record-entries__expanded-row-action-icons .c-medical-record-entries__icon {
  display: inline-block;
  width: 38px;
  min-width: 30px;
  text-align: center;
}

.c-medical-record-entries__expanded-row-key-values-container,
.c-medical-record-entries__expanded-row-header {
  display: flex;
}

.c-medical-record-entries__category-icon-container {
  min-width: 150px;
  margin-right: 24px;
  text-align: right;
}

.c-medical-record__expanded-row-keys {
  min-width: 150px;
  margin: 24px 24px 0 0;
  text-align: right;
  color: var(--grey-65);
}

.c-medical-record__expanded-row-values {
  margin-top: 24px;
  color: var(--grey-40);
}

.c-medical-record__expanded-row-shared-with-container {
  margin-bottom: 0;
  padding-left: 32px;
}

.c-medical-record__expanded-row-shared-with {
  margin-top: 8px;
}

.c-medical-record__expanded-row-share-btn {
  margin-top: 16px;
}

.c-medical-record-entries__cell--mobile {
  display: none;
}

.c-medical-record-shared-entries__actions {
  padding: 16px;
  text-align: center;
}

.c-medical-record-shared-entries__wrapper {
  border-width: 1px 1px 0 1px;
  border-color: var(--grey-90);
  border-style: solid;
  padding: 16px;
}

.c-medical-record-shared-entries__wrapper:last-child {
  border-bottom-width: 1px;
}

.is-disabled .c-medical-record-entries__category-name,
.is-disabled .c-medical-record-entries__category-icon,
.is-disabled .c-medical-record-entries__category-value,
.is-disabled .c-medical-record-entries__share-icon,
.is-disabled .c-medical-record-entries__share-icon:hover,
.is-disabled .c-medical-record__expanded-row-keys,
.is-disabled .c-medical-record__expanded-row-values,
.is-disabled .c-medical-record-entries__cell,
.is-disabled .c-medical-record-entries__cell--mobile,
.is-disabled .c-medical-record-entries__data {
  color: var(--grey-60);
}

.is-disabled .c-medical-record-entries__share-icon:hover {
  cursor: default;
}

/*
 * Breakpoint 1200 for shared medical records
 */

@media only screen and (max-width: 1200px) {
  .c-medical-record-entries__date-icon-container {
    float: right;
  }

  .c-medical-record-entries__info-container {
    margin-left: 32px;
  }

  .c-medical-record-shared-entries__actions--mobile {
    display: inline-block;
    width: 50%;
  }

  .c-medical-record-entries__created-by--mobile {
    color: var(--grey-60);
  }

  .c-medical-record-entries__data-icon--shared {
    color: var(--sky-blue);
    display: inline-block;
    width: 16px;
    vertical-align: top;
  }

  .c-medical-record-entries__data--shared {
    display: inline-block;
    width: calc(100% - 32px);
  }

  .c-medical-record-entries__data-icon--shared .c-medical-record-entries__category-name,
  .c-medical-record-entries__data--shared .c-medical-record-entries__category-name {
    display: inline-block;
    width: calc(100% - 120px);
  }

  .c-medical-record-entries__category-value,
  .c-medical-record-entries__created-by {
    font-size: var(--font-size-small);
  }
}

/*
 * Breakpoint 1200 for medical record entries table
 */

@media only screen and (max-width: 1200px) {
  .c-medical-record-entries__cell {
    display: none;
  }

  .c-medical-record-entries__cell--mobile {
    display: table-cell;
    line-height: var(--line-height-default);
    padding: 16px;
  }

  .c-medical-record-entries__category-name {
    display: inline-block;
    width: calc(100% - 110px);
  }

  .c-medical-record-entries__data-icon {
    display: inline-block;
    width: 16px;
    vertical-align: top;
  }

  .c-medical-record-entries__data {
    display: inline-block;
    width: 100%;
  }

  .c-medical-record-entries__expanded-row-container .c-medical-record-entries__category-name {
    display: inline;
  }

  .c-medical-record-entries__expand-collapse-icon {
    margin-left: 20px;
  }

  .c-medical-record-entries__expanded-row-action-icons
    .c-medical-record-entries__expand-collapse-icon {
    margin-left: 0;
  }

  .c-medical-record-entries__expanded-row-action-icons .c-medical-record-entries__icon {
    width: 48px;
  }
}

/*
 * Breakpoint 768 for expanded row of medical record
 */

@media only screen and (max-width: 767px) {
  .c-medical-record-entries__expanded-row-action-icons {
    top: 32px;
  }

  .c-medical-record-entries__expanded-row-key-values-container {
    margin: 24px 0 24px 32px;
    font-size: 16px;
  }

  .c-medical-record-entries__category-icon-container,
  .c-medical-record__expanded-row-keys {
    min-width: 0;
    text-align: left;
    display: inline-block;
    vertical-align: top;
  }

  .c-medical-record__expanded-row-keys,
  .c-medical-record__expanded-row-values,
  .c-medical-record-entries__category-icon-container {
    margin: 0;
  }

  .c-medical-record-entries__category-icon-container {
    width: 32px;
  }

  .c-medical-record-entries__creator-name {
    color: var(--grey-65);
  }

  .c-medical-record-entries__expanded-row-container {
    padding: 32px;
  }

  .c-medical-record-entries__expanded-row-key-values-container,
  .c-medical-record-entries__expanded-row-header {
    display: block;
  }

  .c-medical-record-entries__category-description-container {
    display: inline-block;
    width: calc(100% - 180px);
  }

  .c-medical-record-entries__category-icon--large {
    font-size: var(--font-size-default);
  }

  .c-medical-record-entries__expanded-row-container .c-medical-record-entries__category-name {
    display: inline;
  }

  .c-medical-record-entries__date-icon-container--shared {
    display: none;
  }

  .c-medical-record-entries__data--shared .c-medical-record-entries__category-name {
    width: 100%;
  }
}
