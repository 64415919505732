.o-tags {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.o-tags__tag {
  display: inline-flex;
  align-items: center;
  margin: 0 8px 8px 0;
  min-height: 30px;
  padding: 0 8px;
  border: 1px solid var(--grey-85);
  border-radius: 5px;
  color: var(--mid-blue);
  font-size: var(--font-size-secondary);
  line-height: var(--line-height-secondary);
  text-decoration: none;
  vertical-align: top;
}

/*
  IE has troubles with vertical flex alignment when only `min-height` is defined
  Following hack resolves the issue:
*/
.o-tags__tag:after {
  content: '';
  min-height: inherit;
  font-size: 0;
}

.o-tags__tag--clickable {
  cursor: pointer;
}

.o-tags__tag--clickable:hover {
  border-color: var(--sky-blue);
  background-color: var(--sky-blue);
  color: white;
}

.o-tags__tag--clickable:active {
  border-color: var(--navy-blue);
  background-color: var(--navy-blue);
  color: white;
}

.o-tags__icon {
  display: inline-block;
  font-size: 1.3em;
  vertical-align: -0.1em;
  margin-right: 0.5em;
}
