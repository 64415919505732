.f-patient-events-container {
  margin-bottom: 48px;
}

.f-patient-events {
  margin: 0 8px;
}

.f-patient-events__description {
  margin-bottom: 24px;
  color: var(--grey-30);
}

.f-patient-events__create-account {
  font-weight: 300;
}

.f-patient-events__error {
  font-weight: 300;
}

.f-patient-events__time {
  display: flex;
  color: var(--grey-30);
}

.f-patient-events__location-error {
  display: none;
  color: var(--pink);
  margin-top: 16px;
}

.f-patient-events__icon {
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

.f-patient-events__submit {
  width: 100%;
  font-size: var(--font-size-small);
  line-height: var(--line-height-secondary);
  text-align: center;
}

@media (min-width: 576px) {
  .f-patient-events {
    margin: 0;
  }

  .f-patient-events-confirmation__action-item {
    display: inline-block;
  }

  .f-patient-events-confirmation__action-item:first-child {
    margin-right: 16px;
  }
}

@media (min-width: 768px) {
  .f-patient-events-container {
    margin-bottom: 64px;
  }

  .f-patient-events__description {
    margin-bottom: 40px;
  }

  .f-patient-events__icon {
    margin-right: 16px;
  }
}

@media (min-width: 1200px) {
  .f-patient-events__time-icon-container {
    position: relative;
    top: -3px;
  }

  .f-patient-events__icon {
    height: 24px;
    width: 24px;
  }
}
