.c-search-form {
  grid-area: 1/1/2/2;
  border-bottom: 1px solid var(--info-200);
  background-color: #fff;
  box-shadow: 0px 3px 3px #d0d3d9;
  border-radius: 0px 0px 8px 8px;
  top: 56px; /* header height */
  z-index: 2;
  height: fit-content;
  margin: 11px 7px 0px 7px;
}

.search-info-button .c-search-form {
  visibility: hidden;
}

.search-info-button .c-search-info {
  display: flex;
}

.search-info-button.mpa .c-search-form {
  display: none;
}

.c-search-form__horizontal {
  display: grid;
  grid-template:
    'for' auto
    'near' auto
    'distance' auto
    'insurance' auto
    'text' auto
    'switch' auto
    'search' auto/
    auto;
  margin: 11px 0px 3px 0px;
}

.c-search-form__area--buttons {
  display: block;
  background-color: var(--info-100);
  margin-top: 16px;
  margin-bottom: 16px;
  padding-bottom: 14px;
  border-bottom: 1px solid #edf0f3;
}

.c-search-form__area {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 100%;
  box-sizing: border-box;
  min-width: 0;
}

.c-search-form__area--for-field {
  grid-area: for;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}

.c-search-form__area--near-field {
  grid-area: near;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.c-search-form__area--text-field {
  grid-area: text;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}

.c-search-form__area--distance-field {
  grid-area: distance;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 8px;
}

.c-search-form__area--insurance-field {
  grid-area: insurance;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}

.c-search-form__group--switch {
  grid-area: switch;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 8px;
  padding-right: 32px;
  padding-top: 16px;
}

.c-search-form__submit-button {
  grid-area: search;
  align-self: flex-end;
  margin-right: 32px;
  overflow: hidden;
  margin: 0 16px 16px 16px;
}

.c-search-form__filter-button {
  grid-area: filter;
  align-self: flex-end;
  overflow: hidden;
  margin-right: 16px;
  display: none;
}

.c-search-form__label {
  font-weight: 600;
  line-height: 22px;
  color: var(--grey-800);
}

@media (min-width: 1024px) {
  .c-search-form__map-button {
    display: none;
  }
}

.c-search-form__map-button {
  border: 1px solid var(--grey-40);
  color: var(--grey-40);
  background-color: white;
}

.is-showing-map .c-search-form__map-button:hover,
.c-search-form__map-button:hover {
  border-color: var(--sky-blue);
  color: white;
  background-color: var(--sky-blue);
}

.is-showing-map .c-search-form__map-button {
  color: var(--mid-blue);
  border-color: var(--mid-blue);
}

@media (min-width: 768px) {
  .c-search-form-sm {
    display: none;
  }
}

.c-search-form-sm__summary {
  display: flex;
  align-items: center;
  padding: 16px var(--grid-margin);
  background-color: white;
  border-bottom: 1px solid var(--grey-96);
}

.is-expanded .c-search-form-sm__summary {
  display: none;
}

.c-search-form-sm__search-button {
  margin-right: 16px;
  font-size: 24px;
}

.c-search-form-sm__search-terms {
  width: 100%;
  margin-right: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--mid-blue);
  cursor: text;
}

.c-search-form-sm__searched-field {
  color: var(--navy-blue);
}

.c-search-form-sm__searched-location {
  color: var(--mid-blue);
}

.c-search-form-sm__map-button {
  height: 48px;
  width: 48px;
  padding-top: 4px;
  box-sizing: border-box;
  border: 1px solid var(--mid-blue);
  border-radius: 5px;
  color: var(--mid-blue);
  text-align: center;
  flex-shrink: 0;
  cursor: pointer;
  user-select: none;
}

.c-search-form-sm__map-button:hover {
  color: var(--sky-blue);
  border-color: var(--sky-blue);
}

.c-search-form-sm__map-button:active {
  color: var(--navy-blue);
  border-color: var(--navy-blue);
}

.is-showing-map .c-search-form-sm__map-button-content--map {
  display: none;
}

.c-search-form-sm__map-button-content--list {
  display: none;
}

.is-showing-map .c-search-form-sm__map-button-content--list {
  display: block;
}

.c-search-form-sm__map-button-label {
  line-height: 10px;
}

.c-search-form-sm__icon {
  font-size: 24px;
}

.c-search-form-sm__group,
.c-search-form-sm__group--no-label {
  margin: 0 16px 10px;
}

.c-search-form-sm__group--no-label {
  padding-top: 16px;
}

.o-switch__form-group {
  max-height: 30px;
}

.c-search-info {
  display: none;
  grid-area: 1/1/2/2;
  justify-content: center;
  padding: 7px 7px 0 7px;
}

.c-search-info button {
  z-index: 2;
}

@media (min-width: 768px) {
  .c-search-form__horizontal {
    display: grid;
    grid-template:
      'for          near      filter      search' auto
      'gap          gap       gap       gap' 16px
      'buttons      buttons   buttons   buttons' auto /
      6fr 6fr 2fr 3fr;
    margin: 16px 0 0 0;
  }

  .c-search-form {
    position: sticky;
    height: initial;
    grid-area: search-form;
    margin: 0;
  }

  .c-search-form-top {
    top: 104px; /* nav height */
  }

  .c-search-form__area--buttons {
    display: none;
    grid-area: buttons;
    grid-template:
      'distance insurance .' auto
      'gap gap .' 16px
      'text text switch' auto /
      6fr 6fr 5fr;
    padding: 8px 0 16px 0;
    border-top: 1px solid var(--info-200);
    border-radius: 0 0 8px 8px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .is-toggle-active .c-search-form__area--buttons {
    display: grid;
  }

  .c-search-form__area--for-field {
    padding-left: 16px;
    padding-top: 0;
  }

  .c-search-form__submit-button {
    margin: 0 16px 0 0;
  }

  .c-search-form__group--switch {
    justify-content: initial;
    padding-right: 16px;
    padding-top: 0;
  }

  .c-search-form__filter-button.open {
    display: inline-flex;
  }

  .c-search-form__filter-button.close {
    display: none;
  }

  .is-toggle-active .c-search-form__filter-button.open {
    display: none;
  }

  .is-toggle-active .c-search-form__filter-button.close {
    display: inline-flex;
  }

  .c-search-form__area--near-field {
    padding-left: 0;
    padding-right: 16px;
    padding-top: 0;
  }

  .c-search-form__area--insurance-field {
    padding-left: 0;
    padding-right: 16px;
    padding-top: 0px;
  }

  .c-search-form__area--distance-field {
    padding-top: 0;
  }

  .c-search-form__area--text-field {
    padding-top: 0;
  }

  .c-search-info {
    display: none;
  }

  .search-info-button .c-search-form {
    visibility: visible;
  }

  .search-info-button .c-search-info {
    display: none;
  }

  .search-info-button.mpa .c-search-form {
    display: block;
  }
}

@media (min-width: 1024px) {
  .c-search-form__horizontal {
    grid-template:
      'for          near      filter      search' auto
      'gap          gap       gap       gap' 16px
      'buttons      buttons   buttons   buttons' auto /
      6fr 6fr 2fr 3fr;
    margin: 16px 0 0 0;
  }

  .c-search-form__area--buttons {
    display: none;
    grid-area: buttons;
    grid-template: 'distance insurance text switch' auto / 5fr 8fr 8fr 5fr;
    padding: 8px 0 16px 0;
    background-color: var(--info-100);
    border-top: 1px solid var(--info-200);
    border-radius: 0 0 8px 8px;
  }

  .c-search-form__area--text-field {
    padding-left: 0;
  }

  .c-search-form__area--for-field {
    grid-area: for;
    padding-left: 16px;
    padding-right: 16px;
  }
}
