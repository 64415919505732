.c-search-results-page {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  background-color: var(--light-grey-background);
  margin-top: 56px; /* header-height */
}

.mpa {
  grid-template-rows: auto 1fr;
}

.c-search-results-page__no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  color: var(--grey-65);
}

.c-search-results-page__no-results-image {
  width: 250px;
  height: 250px;
  margin-bottom: 56px;
}

.c-search-results {
  grid-area: 2/1/3/2;
  margin: 8px 8px 8px 8px;
  z-index: 2;
}

.c-search-results.empty {
  background-color: var(--light-grey-background);
  margin: 8px 0 8px 0;
}

.c-search-results__load-more {
  display: flex;
}

.c-search-results__load-more-button {
  margin: 32px auto 32px;
}

.c-search-results__load-more-button.is-hidden {
  display: none;
}

.c-search-results-page__no-more-results.o-container--fluid.is-hidden {
  display: none;
}

@media (min-width: 768px) {
  .c-search-results-page {
    grid-template:
      'search-form search-form' auto
      'results map' 1fr /
      7fr 5fr;
  }

  .c-search-results {
    grid-area: results;
    margin: 12px 6px 0px 12px;
    z-index: initial;
  }

  .c-search-results.empty {
    background-color: transparent;
    margin: 12px 6px 0px 12px;
  }

  .c-search-results-page:not(.logged-in) {
    margin-top: 104px; /* header-height */
  }
}

@media (min-width: 1024px) {
  .c-search-results-page {
    grid-template:
      'search-form search-form' auto
      'results map' 1fr /
      7fr 5fr;
  }

  .c-search-results {
    grid-area: results;
    margin: 16px 8px 0px 32px;
    z-index: initial;
  }
}
