/*
 * Use negative values to compensate the top padding of the container one-level above,
 * and have a div filled with background color.
 * */

.c-hero {
  position: relative;
  margin-bottom: -24px;
  padding-top: 40px;
  background-color: var(--grey-100);
}

.c-hero__title {
  margin-bottom: 24px;
  font-size: var(--font-size-hero);
  font-weight: 600;
  line-height: var(--line-height-hero);
  letter-spacing: var(--letter-spacing-hero);
  color: var(--grey-800);
}

.c-hero__title em {
  font-style: normal;
  color: var(--dark);
}

.c-hero__description {
  display: grid;
  grid-template:
    'hero-illustration' auto /
    12fr;
}

/*
* Width is calculated in order to align image to the labels in search form
* 112 = (icon container width) 56 * 2
*/

.c-hero__image {
  grid-area: hero-illustration;
  width: calc(100% - 112px);
  margin: auto;
}

.c-hero__description-box {
  grid-area: hero-description-text;
  display: none;
  margin-bottom: 24px;
  font-weight: 300;
}

.c-hero__description-text {
  margin-bottom: 24px;
  font-size: var(--font-size-default);
}

@media (min-width: 768px) {
  .c-hero {
    padding-top: 88px;
  }

  .c-hero__image {
    width: 100%;
  }

  .c-hero__description {
    grid-template:
      'hero-illustration space hero-description-text' auto /
      11fr 2fr 11fr;
  }

  .c-hero__description-box {
    display: block;
    margin-bottom: 16px;
  }

  .c-hero__title {
    margin-bottom: 48px;
  }
}

@media (min-width: 960px) {
  .c-hero {
    padding-top: 72px;
  }

  .c-hero__description {
    grid-template:
      'indent hero-illustration mid-space space-top' auto
      'indent hero-illustration mid-space hero-description-text' auto
      'indent hero-illustration mid-space hero-description-text' auto
      'indent hero-illustration mid-space space-bottom' auto /
      1fr 5fr 1fr 5fr;
  }
}

@media (min-width: 1312px) {
  .c-hero {
    padding-top: 96px;
  }

  .c-hero__title {
    margin-bottom: 56px;
  }

  .c-hero__description-text {
    font-size: var(--font-size-normal);
  }

  .c-hero__description {
    grid-template:
      'indent hero-illustration mid-space mid-top-space right-space' auto
      'indent hero-illustration mid-space hero-description-text right-space' auto
      'indent hero-illustration mid-space hero-description-text right-space' auto
      'indent hero-illustration mid-space mid-bottom-space right-space' auto /
      2fr 11fr 1fr 11fr 2fr;
  }
}
