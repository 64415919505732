.s-toggle--visible-when-active {
  display: none;
}

.is-toggle-active .s-toggle--visible-when-active {
  display: block;
}

.is-toggle-active .s-toggle--visible-when-inactive {
  display: none;
}
