.c-password-requirements {
  color: var(--grey-30);
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
}

.c-password-requirements__title {
  font-size: var(--font-size-h6);
  line-height: var(--line-height-h6);
}

.c-password-requirements__icon {
  font-size: 16px;
  color: var(--navy-blue);
  margin-right: 8px;
}

.c-password-requirements__icon svg {
  width: 1em;
}

.c-password-requirements__icon.is-active {
  color: var(--sky-blue);
}

.c-password-requirements__icon .fa-circle-check {
  display: none;
}

.c-password-requirements__icon.is-active .fa-circle-check {
  display: block;
}

.c-password-requirements__icon.is-active .fa-circle-small {
  display: none;
}

.c-password-requirements__item {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}
