.c-footer {
  grid-area: footer;
  background-color: var(--dark);
  color: white;
  padding-top: 40px;
}

@media print {
  .c-footer {
    display: none;
  }
}

.c-footer__portal {
  grid-area: portal;
}

.c-footer__legal {
  grid-area: legal;
}

.c-footer__company {
  grid-area: company;
}

.c-footer__company address {
  font-style: normal;
}

.c-footer__social-media {
  grid-area: social-media;
}

.c-footer__section-title {
  color: #fff;
  font-weight: 600;
  font-size: var(--font-size-h5);
  line-height: var(--line-height-h5);
  letter-spacing: var(--letter-spacing-h5);
  margin-bottom: 16px;
}

.c-footer__cta {
  grid-area: doctors;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  border-bottom: 1px solid white;
}

.c-footer__cta-text p:first-child {
  color: #fff;
  font-weight: 600;
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  letter-spacing: var(--letter-spacing-h3);
  margin-bottom: 0;
}

.c-footer__grid {
  display: grid;
  grid-template:
    'doctors doctors' auto
    'separator separator' 24px
    'portal legal' auto
    'separator2 separator2' 32px
    'company social-media' auto /
    1fr 1fr;
  column-gap: 24px;
  margin-bottom: 32px;
}

@media (min-width: 768px) {
  .c-footer__grid {
    grid-template:
      'doctors doctors doctors doctors' auto
      'separator separator separator separator' 24px
      'portal legal company social-media' auto /
      1fr 1fr 1fr 1fr;
  }

  .c-footer__cta {
    flex-direction: row;
    align-items: center;
    padding-bottom: 0;
  }

  .c-footer__cta-text {
    margin-right: 32px;
  }

  .c-footer__button {
    margin-bottom: 16px;
  }
}

@media (min-width: 1024px) {
  .c-footer__grid {
    grid-template:
      'portal legal company social-media doctors' auto /
      1fr 1fr 1fr 1fr 1fr;
    column-gap: 32px;
  }

  .c-footer__cta {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 8px;
    border-bottom: none;
  }

  .c-footer__cta-text {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .c-footer__grid {
    grid-template:
      'portal legal company social-media doctors' auto /
      1fr 1fr 1fr 1fr 5fr;
  }

  .c-footer__cta {
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 24px;
  }

  .c-footer__cta-text {
    margin-right: 48px;
  }
}

.c-footer__link-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  line-height: 24px;
}

.c-footer__link-list-icon-container {
  display: flex;
  align-items: center;
}

.c-footer__link-list-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.c-footer__button {
  display: inline-block;
  text-align: center;
  font-size: 16px;
  white-space: nowrap;
}

.c-footer__button,
.c-footer__button:visited {
  color: var(--mid-blue);
  background-color: var(--blue1);
}

.c-footer__button:hover {
  background-color: var(--blue2);
}

.c-footer__button:active {
  background-color: var(--sky-blue);
}

@media (min-width: 1200px) {
  .c-footer__button {
    box-sizing: border-box;
    width: 244px;
    margin: 8px 0 0 8px;
    padding: 0 18px;
  }
}

.c-footer__details {
  border-top: 1px solid white;
  padding: 8px 0 24px;
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
}

.c-footer__details p {
  color: #fff;
}

.c-footer__social-link,
.c-footer__social-link:visited,
.c-footer__social-link:hover,
.c-footer__social-link:active {
  display: inline-block;
  margin-left: 8px;
  color: white;
  font-size: 14px;
  text-decoration: none;
}
