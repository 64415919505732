.c-login__side-image {
  display: block;
  width: 240px;
}

@media (min-width: 768px) {
  .c-login__side-image {
    margin-left: 56px;
    width: 145px;
  }
}

.c-login__side-push {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 64px;
}

@media (min-width: 768px) {
  .c-login__side-push {
    flex-direction: row;
    height: auto;
  }
}

.c-login__secondary-link {
  font-weight: normal;
  font-size: 14px;
}

@media (min-width: 1200px) {
  .c-login__secondary-link {
    font-size: var(--font-size-default);
  }
}

@media (max-width: 768px) {
  .c-login__last-form-group {
    margin-bottom: 8px;
  }
}
