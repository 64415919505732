.o-clickable,
.t-clickable a {
  text-decoration: none;
  color: var(--mid-blue);
  font-weight: 600;
  cursor: pointer;
  border: none;
  background: none;
}

.o-clickable:hover,
.t-clickable a:hover {
  text-decoration: none;
  color: var(--sky-blue);
}

.o-clickable:active,
.t-clickable a:active {
  text-decoration: none;
  color: var(--navy-blue);
}

.t-clickable--inherit-color a {
  color: inherit;
  text-decoration: underline;
}

.t-clickable--inherit-color a:hover {
  color: inherit;
  text-decoration: underline;
}

.t-clickable--inherit-color a:active {
  color: inherit;
  text-decoration: underline;
}

.o-clickable--block {
  background-color: var(--mid-blue);
  cursor: pointer;
}

.o-clickable--block:hover {
  background-color: var(--sky-blue);
}

.o-clickable--block:active {
  background-color: var(--navy-blue);
}

.o-clickable--white,
.o-clickable--white:link,
.o-clickable--white:visited,
.o-clickable--white:hover,
.o-clickable--white:active {
  color: white;
  text-decoration: none;
}

.o-clickable--white:hover {
  text-decoration: underline;
}

.o-clickable--light {
  font-weight: normal;
}

.o-clickable--dark {
  color: var(--dark);
}

.o-clickable--dark:hover {
  color: var(--dark);
  background: rgba(0, 163, 226, 0.1);
  border-radius: 5px;
}

.o-clickable--grey-50 {
  color: var(--grey-50);
}

.o-clickable--grey-50:hover {
  color: var(--grey-50);
  background: var(--grey-95);
  border-radius: 5px;
}
