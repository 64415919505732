.c-video-consultation-additional-help {
  padding: 16px 0;
}

.c-video-consultation-additional-help__item-container {
  margin-bottom: 0;
}

.c-video-consultation-additional-help__item-title {
  color: var(--navy-blue);
}

.c-video-consultation-additional-help__item-description {
  color: var(--grey-30);
}

@media (min-width: 768px) {
  .c-video-consultation-additional-help__item-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
  }

  .c-video-consultation-additional-help__item-container:last-child {
    margin-bottom: 0;
  }

  .c-video-consultation-additional-help__item-title {
    min-width: 200px;
  }

  .c-video-consultation-additional-help__item-description {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .c-video-consultation-additional-help {
    padding: 32px 0;
  }
}
