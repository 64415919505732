.o-form {
  max-width: 1200px;
}

.o-text-field {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--mid-blue);
  border-radius: 0;
  padding: 1px 2px;
  font-size: var(--font-size-default);
  font-weight: 300;
  background: none;
  color: var(--mid-blue);
  line-height: 24px;
  box-sizing: border-box;
}

.o-text-field:focus {
  outline: none;
}

.o-text-field-search {
  height: 56px;
  width: 100%;
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  letter-spacing: 0.02em;
  color: var(--dark);
  box-sizing: border-box;
  padding: 16px 10px;
  border: 1px solid var(--info-400);
  border-radius: 8px;
}

.o-text-field-search:focus {
  outline: none;
  border: 1px solid var(--sky-blue);
}

.o-text-field::placeholder,
.o-text-field-search::placeholder {
  color: var(--grey-45);
  opacity: 1;
  text-overflow: ellipsis;
}

@media (min-width: 768px) {
  .o-text-field-search {
    height: 40px;
    padding: 8px 10px;
  }
}

.o-text-area {
  border-radius: 5px;
  border: 1px solid var(--mid-blue);
  width: 100%;
  padding: 16px;
  color: var(--mid-blue);
  background-color: white;
  line-height: normal;
  resize: none;
  font-family: inherit;
  box-sizing: border-box;
}

.o-icon-wrapper {
  position: relative;
}

.o-icon-wrapper__icon {
  position: absolute;
  top: 0;
  right: 6px;
  bottom: 0;
  width: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.error .o-text-field,
.error .o-text-area {
  border-color: var(--pink);
}

.o-checkbox {
  margin-left: 0;
}

.o-form-label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  transform: translate(0, 0);
  font-size: var(--font-size-secondary);
  line-height: var(--line-height-secondary);
  color: var(--navy-blue);
}

.o-form-label abbr {
  padding-left: 0.25em;
  text-decoration: none;
}

.error .o-form-label {
  color: var(--pink);
}

.o-form-label--checkbox {
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  color: var(--mid-blue);
  font-weight: 600;
}

.o-text-field:disabled,
.o-text-area:disabled {
  opacity: 0.3;
}

.o-form-field-note {
  padding: 4px 0;
  font-size: var(--font-size-secondary);
  line-height: var(--line-height-secondary);
  color: var(--grey-50);
  font-weight: normal;
}

.o-form-field-note--no-padding {
  padding: 0;
}

.error .help-block {
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  color: var(--pink);
}

.t-checkbox-with-inline-label label {
  display: block;
  padding-left: 24px;
  margin: 10px auto;
}

.t-checkbox-with-inline-label.required label::after {
  content: '*';
  padding-left: 0.25em;
}

.t-checkbox-with-inline-label input[type='checkbox'] {
  position: relative;
  right: 24px;
  margin-right: -20px;
  margin-left: 0;
  vertical-align: middle;
}

.t-checkbox-with-inline-label--light label {
  font-weight: normal;
  color: var(--grey-30);
}

.t-checkbox-with-inline-label__hidden-input label {
  padding-left: 0;
}
