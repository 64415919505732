.c-video-consultation-advantages {
  padding: 24px 0;
}

.c-video-consultation-advantages__content {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  margin-left: 32px;
}

.c-video-consultation-advantages__item {
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  margin-bottom: 32px;
  color: var(--grey-30);
}

.c-video-consultation-advantages__icon {
  align-self: center;
  min-width: 45px;
  margin-bottom: 16px;
  color: var(--sky-blue);
  font-size: 36px;
}

@media (min-width: 768px) {
  .c-video-consultation-advantages__content {
    margin-right: 0;
    margin-left: 0;
  }

  .c-video-consultation-advantages__item {
    flex-direction: row;
    margin-bottom: 24px;
  }

  .c-video-consultation-advantages__icon {
    margin-right: 32px;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .c-video-consultation-advantages__content {
    flex-direction: row;
  }

  .c-video-consultation-advantages__item {
    flex-direction: column;
    margin-right: 32px;
  }

  .c-video-consultation-advantages__item:last-child {
    margin-right: 0;
  }

  .c-video-consultation-advantages__icon {
    margin-right: 0;
    margin-bottom: 24px;
    font-size: 64px;
  }
}
