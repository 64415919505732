.o-checkbox-container {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.o-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 24px;
  width: 24px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  border-radius: 5px;
}

.o-checkbox__checkmark {
  position: absolute;
  height: 24px;
  width: 24px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  border: 1px solid var(--grey-85);
  border-radius: 5px;
}

.o-checkbox-container:hover .o-checkbox ~ .o-checkbox__checkmark,
.o-checkbox-container .o-checkbox:focus ~ .o-checkbox__checkmark {
  border-color: var(--mid-blue);
  background-color: var(--grey-90);
}

.o-checkbox-container .o-checkbox:checked ~ .o-checkbox__checkmark {
  background-color: var(--mid-blue);
  border: none;
}

.o-checkbox-container .o-checkbox:focus-visible ~ .o-checkbox__checkmark {
  outline: auto;
}

.o-checkbox__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.o-checkbox-container .o-checkbox:checked ~ .o-checkbox__checkmark:after {
  display: block;
}

.o-checkbox-container .o-checkbox__checkmark:after {
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

@media (min-width: 1312px) {
  .o-checkbox-container {
    padding-left: 56px;
  }

  .o-checkbox,
  .o-checkbox__checkmark {
    height: 32px;
    width: 32px;
    border-radius: 8px;
  }

  .o-checkbox-container .o-checkbox__checkmark:after {
    width: 8px;
    height: 15px;
  }
}
