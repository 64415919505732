.o-nested-menu__content {
  display: none;
  border-left: 4px solid transparent;
  box-sizing: border-box;
}

.o-nested-menu__content--active {
  background-color: var(--light-grey-background);
  border-color: var(--dark);
}

.o-nested-menu__toggle.is-opened .o-nested-menu__content {
  display: block;
}

.o-nested-menu__toggle.is-opened .o-nested-menu__toggle-up.fa-angle-up,
.o-nested-menu__toggle:not(.is-opened) .o-nested-menu__toggle-down.fa-angle-down {
  display: inline;
}

.o-nested-menu__toggle.is-opened .o-nested-menu__toggle-down.fa-angle-down,
.o-nested-menu__toggle:not(.is-opened) .o-nested-menu__toggle-up.fa-angle-up {
  display: none;
}
