/* JavaScript will position this div at the bottom of the popup tip. */
.c-map-popup {
  cursor: auto;
  height: 0;
  position: absolute;
  width: 100%; /* The max width of the info window. */
}

/* The popup bubble styling. */
.c-map-popup__content {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);

  /* Style the bubble. */
  background-color: white;
  border-radius: 8px;
  font-family: var(--font-family);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  overflow-y: auto;
  max-height: 50vh;
  min-width: 300px;
  max-width: 355px;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.5);
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.c-map-popup__anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 0;
}

/* This element draws the tip. */
.c-map-popup__anchor::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;

  /* Center the tip horizontally. */
  transform: translate(-50%, 0);

  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;

  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid var(--mid-blue);
}

.is-mouse-over-button .c-map-popup__anchor::after {
  border-top: 8px solid var(--sky-blue);
}

.is-mouse-held-on-button .c-map-popup__anchor::after {
  border-top: 8px solid var(--navy-blue);
}

.c-map-popup__header {
  display: flex;
  justify-content: flex-end;
  height: 30px;
  line-height: 30px;
  color: var(--grey-60);
}

.c-map-popup__page-indicator {
  font-weight: 600;
}

.c-map-popup__navigation {
  display: none;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 8px;
}

.c-map-popup__navigation.is-visible {
  display: flex;
}

.c-map-popup__close-button {
  width: 30px;
  text-align: center;
  height: 100%;
}

.c-map-popup__practice {
  display: none;
}

.c-map-popup__practice.is-current {
  display: block;
}

.c-map-popup__practice-description {
  padding: 0 16px 16px;
}

.c-map-popup__heading {
  color: var(--navy-blue);
  line-height: var(--line-height-default);
  max-height: 10%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.c-map-popup__sub-heading {
  margin-bottom: 16px;
  color: var(--grey-60);
  font-weight: 600;
  max-height: 10%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.c-map-popup__location {
  color: var(--sky-blue);
  font-size: var(--font-size-secondary);
  line-height: var(--line-height-tiny);
}

.c-map-popup__button {
  width: 100%;
  display: flex;
  border-radius: 0 0 8px 8px;
}

.c-map_popup__loading-indicator {
  text-align: center;
  padding: 32px 0;
  color: var(--grey-60);
}
