.t-text-field .PhoneInput {
  font-size: var(--font-size-default);
  border-bottom: 1px solid var(--mid-blue);
  color: var(--mid-blue);
  padding-bottom: 2px;
}

.t-text-field .PhoneInputCountry {
  padding-left: 1px;
}

.t-text-field .PhoneInput button:focus-visible {
  outline: revert !important;
}

.t-text-field .PhoneInput input {
  font-weight: 300;
  color: var(--mid-blue);
  outline: none;
}
