.o-notch {
  background-color: var(--mid-blue);
  width: 32px;
  height: 2px;
}

.o-notch--white {
  background-color: white;
}

.o-notch--big {
  background-color: var(--mid-blue);
  width: 54px;
  height: 4px;
  margin-top: 16px;
}
