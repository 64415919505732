.error-page__content {
  margin-bottom: 64px;
  padding-top: 72px;
}

.error-page__image {
  display: block;
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .error-page__image {
    width: 484px;
  }
}
