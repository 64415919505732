/*
  XS - default
  SM - mobile: 576px
  MD - tablet portrait: 768px
  LG - tablet landscape: 1024px
  XL - desktop: 1200px
*/

@media (min-width: 576px) {
  .h-only-xs {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .h-only-sm {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .h-only-lg {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .h-only-xl {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .h-hide-xs {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .h-hide-sm {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .h-hide-lg {
    display: none !important;
  }
}

.h-margin-bottom--0 {
  margin-bottom: 0;
}

/* As we are following the 8-Point Grid system, all margin helpers should be a multiple of 8 */
.h-margin-bottom--8 {
  margin-bottom: 8px;
}

.h-margin-bottom--16 {
  margin-bottom: 16px;
}

.h-margin-bottom--24 {
  margin-bottom: 24px;
}

.h-margin-bottom--32 {
  margin-bottom: 32px;
}

.h-margin-bottom--40 {
  margin-bottom: 40px;
}

.h-margin-bottom--48 {
  margin-bottom: 48px;
}

.h-margin-bottom--56 {
  margin-bottom: 56px;
}

.h-margin-right--8 {
  margin-right: 8px;
}

.h-margin-right--16 {
  margin-right: 16px;
}

.h-margin-right--24 {
  margin-right: 24px;
}

.h-margin-top--8 {
  margin-top: 8px;
}

.h-padding-bottom--0 {
  padding-bottom: 0;
}

.h-padding-left--16 {
  padding-left: 16px;
}

.h-display--block {
  display: block;
}

.h-display--hidden {
  display: none;
}

.h-display--inline-block {
  display: inline-block;
}

.h-display--flex {
  display: flex;
}

.h-flex-end {
  justify-content: flex-end;
}

.h-color--white {
  color: white;
}

.h-color--navy {
  color: var(--navy-blue);
}

.h-color--mid-blue {
  color: var(--mid-blue);
}

.h-color--sky-blue {
  color: var(--sky-blue);
}

.h-color--grey-30 {
  color: var(--grey-30);
}

.h-color--grey-40 {
  color: var(--grey-40);
}

.h-color--grey-50 {
  color: var(--grey-50);
}

.h-color--grey-60 {
  color: var(--grey-60);
}

.h-color--grey-70 {
  color: var(--grey-70);
}

.h-color--pink {
  color: var(--pink);
}

.h-color--dark {
  color: var(--dark);
}

.h-max-width--1024 {
  max-width: 1024px;
}

.h-full-width {
  width: 100%;
}

.h-full-height {
  height: 100%;
}

.h-font-size--13 {
  font-size: var(--font-size-secondary);
}

.h-font-size--18 {
  font-size: var(--font-size-normal);
}

.h-font-size--24 {
  font-size: var(--font-size-large);
}

.h-font-weight--300 {
  font-weight: 300;
}

.h-font-weight--600 {
  font-weight: 600;
}

.h-font-weight--700 {
  font-weight: 700;
}

.h-text-center {
  text-align: center;
}

.h-no-outline {
  outline: none;
}

/* -ms prefix can be removed after we end Internet Explorer support*/
.h-word-break {
  -ms-word-break: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
