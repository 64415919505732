.s-video-container {
  position: relative;
}

.s-video-container__poster {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.is-played .s-video-container__poster {
  display: none;
}

.s-video-container__video {
  display: none;
  width: 100%;
  height: auto;
}

.is-played .s-video-container__video {
  display: block;
}
