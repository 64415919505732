.c-map,
.leaflet-map {
  z-index: 1;
  display: block;
  grid-area: 1/1/2/2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .c-map,
  .leaflet-map {
    z-index: initial;
    margin: 12px 12px 16px 6px;
    grid-area: map;
    position: relative;
    top: initial;
    left: initial;
    right: initial;
  }

  .leaflet-map {
    z-index: 1;
  }

  .c-map__sticky-wrapper,
  .leaflet-map__sticky_wrapper {
    position: sticky;
    top: 164px; /* nav height + c-search-form container height + extra padding = 57 + 79 + 28 */
  }

  .c-map-top {
    top: 212px; /* nav height + c-search-form container height + extra padding= 105 + 79 + 28 */
  }
}

@media (min-width: 1024px) {
  .c-map,
  .leaflet-map {
    margin: 16px 32px 16px 8px;
  }
}

.c-map__consent-overlay {
  position: absolute;
  inset: 0;
  background-color: hsla(0, 0%, 0%, 0.1);
  display: flex;
  visibility: hidden;
  justify-content: center;
}
.c-map__consent-text {
  text-align: center;
  max-width: 80%;
  margin-top: 200px;
}

.c-map #map,
#leaflet-map {
  width: 100%;
  height: calc(100vh - 164px - 10px);
}

.c-map__button {
  height: 40px;
  margin-top: 10px;
  line-height: 36px;
  font-size: var(--font-size-map-button);
}

@media (max-width: 576px) {
  .c-map__button {
    left: 10px !important; /* !important is necessary to override the dynamically created Search Here buttons' position on the map*/
    margin-top: 60px;
  }
}

@media (min-width: 768px) {
  .c-map #map {
    height: 100vh;
  }
}
