:root {
  /* FONTS */
  --font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;

  /* COLORS */
  --navy-blue: #104080;
  --mid-blue: #0067b2;
  --sky-blue: #00a3e2;
  --light-baby-blue: #cbeef9;
  --sun: #ffce02;
  --orange: #fab710;
  --light-orange: #fbc641;
  --dark-orange: #d29804;
  --beeswax-orange: #fdebc4;
  --pink: #e61657;
  --light-pink: #ed4077;
  --dark-pink: #b61145;
  --light-baby-pink: #fbb9b9;
  --grey-30: #4d4d4d;
  --grey-40: #666;
  --grey-45: #757575;
  --grey-50: #808080;
  --grey-60: #999;
  --grey-65: #a6a6a6;
  --grey-70: #b3b3b3;
  --grey-80: #ccc;
  --grey-85: #d9d9d9;
  --grey-90: #e6e6e6;
  --grey-95: #f2f2f2;
  --grey-96: #f5f5f5;
  --grey-98: #fafafa;
  --grey-99: #fcfcfc;

  /* NEW COLORS */
  --blue1: #e6f0f7;
  --blue2: #d3e4f1;

  /* Mapping the color definitions in https://www.figma.com/file/imYtJnUeMg03hSYiNpZw4o/Mobile-App?node-id=910%3A5517 */
  /* Neutral */
  --dark: #071e3c;

  /* Secondary */
  --grey-100: #f9fafb;
  --grey-300: #dae0e7;
  --grey-700: #8093a9;
  --grey-800: #4e6074;
  --grey-900: #1b2128;

  /* Background */

  --light-grey-background: #f3f5f7;
  --light-blue-background: #e6f0f7;
  --light: #fefefe;

  /* COLORS FOR AVATARS */
  --avatar-blue: #dcebf6;
  --avatar-lilac: #e3e3f2;
  --avatar-green: #e3f2e3;
  --avatar-red: #f2e3e3;
  --avatar-orange: #f5eccd;
  --avatar-light-blue: #e4f8fa;
  --avatar-yellow: #efefd5;

  /* Info */
  --info-100: #f9fafb;
  --info-200: #edf0f3;
  --info-300: #dae0e7;
  --info-350: #d3dae1;
  --info-400: #cbd4dc;
  --info-500: #bcc6d2;
  --info-600: #9eadbd;
  --info-700: #8093a9;
  --info-800: #4e6074;
  --info-900: #1b2128;

  /* WIDTHS */
  --content-width: 1170px;

  /* MARGINS */
  --grid-margin: 40px;

  /* PADDINGS */
  --container-padding: 24px 0 48px;
  --content-padding: 24px 48px 48px;

  /* DYNAMIC (COMPONENT) FONT SIZES, LINE HEIGHTS & LETTER SPACING */
  --font-size-hero: 38px;
  --line-height-hero: 40px;
  --letter-spacing-hero: -0.03em;
  --font-size-h1: 26px;
  --line-height-h1: 32px;
  --letter-spacing-h1: -0.03em;
  --font-size-h2: 24px;
  --line-height-h2: 32px;
  --letter-spacing-h2: -0.03em;
  --font-size-h3: 22px;
  --line-height-h3: 24px;
  --letter-spacing-h3: -0.03em;
  --font-size-h4: 19px;
  --line-height-h4: 24px;
  --letter-spacing-h4: -0.02;
  --font-size-h5: 18px;
  --line-height-h5: 24px;
  --letter-spacing-h5: -0.01em;
  --font-size-h6: 12px;
  --line-height-h6: 16px;
  --font-size-button: 14px;
  --font-size-map-button: 16px;

  /* STATIC FONT SIZES & LINE HEIGHTS */
  --font-size-tiny: 11px;
  --line-height-tiny: 16px;
  --font-size-secondary: 13px;
  --font-size-small: 14px;
  --line-height-secondary: 16px;
  --font-size-default: 16px;
  --font-size-normal: 18px;
  --line-height-default: 24px;
  --font-size-medium: 21px;
  --line-height-medium: 28px;
  --icon-size-default: 16px;
  --font-size-large: 24px;

  /* MEDIA WIDTH SIZES */
  --tablet-min-width: 768;
  --desktop-min-width: 1024;
}

@media (min-width: 768px) and (max-width: 1200px) {
  :root {
    --font-size-h1: 40px;
    --line-height-h1: 48px;
    --font-size-h2: 32px;
    --line-height-h2: 40px;
    --font-size-h3: 24px;
    --line-height-h3: 32px;
    --font-size-h4: 20px;
    --line-height-h4: 24px;
    --container-padding: 24px 0 64px;
  }
}

@media (min-width: 768px) and (max-width: 1312px) {
  :root {
    --font-size-hero: 56px;
    --line-height-hero: 56px;
  }
}

@media (min-width: 1200px) {
  :root {
    --font-size-hero: 67px;
    --line-height-hero: 72px;
    --font-size-h1: 48px;
    --line-height-h1: 56px;
    --font-size-h2: 40px;
    --line-height-h2: 48px;
    --font-size-h3: 32px;
    --line-height-h3: 40px;
    --font-size-h4: 24px;
    --line-height-h4: 32px;
    --container-padding: 24px 0 64px;
  }
}

@media (min-width: 1312px) {
  :root {
    --font-size-hero: 67px;
    --line-height-hero: 72px;
  }
}
