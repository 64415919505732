.f-back-to-top {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  border: 1px solid var(--grey-90);
  border-radius: 100%;
  font-size: 24px;
  background-color: white;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

@media (max-width: 767px) {
  .f-back-to-top {
    display: none;
  }
}

@media (max-width: 1023px) {
  .is-showing-map .f-back-to-top {
    --map-width: calc(100% * (1 / 3));
    --padding: 16px;
    right: calc(var(--map-width) + var(--padding));
  }
}

@media (min-width: 1024px) {
  .f-back-to-top {
    --map-width: calc(100% * (5 / 12));
    --padding: 16px;
    right: calc(var(--map-width) + var(--padding));
  }
}

.f-back-to-top.is-visible {
  pointer-events: all;
  opacity: 1;
  transition: opacity 0.3s;
}
