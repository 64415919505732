.c-top-menu {
  grid-area: header;
  padding: 0 16px;
  height: 56px;
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid var(--grey-90);
}

@media (min-width: 768px) {
  .c-top-menu {
    align-items: center;
    padding: 0 40px 0 16px;
    height: 104px;
  }
}

@media print {
  .c-top-menu {
    display: none;
  }
}

@media (min-width: 768px) {
  .c-top-menu--internal {
    height: 56px;
  }
}

.c-top-menu__logo-link {
  display: flex;
  align-items: center;
  height: 100%;
}

.c-top-menu__logo {
  height: 32px;
}

@media (min-width: 768px) {
  .c-top-menu__logo {
    height: 48px;
  }
}

.c-top-menu--internal .c-top-menu__logo {
  height: 32px;
}

.c-top-menu__items {
  color: var(--mid-blue);
}

.c-top-menu__user {
  align-self: center;
  margin: 0 8px;
  border-radius: 5px;
  padding: 0;
}

.c-top-menu__user:hover {
  background-color: var(--sky-blue);
}

.c-top-menu__user:active {
  background-color: var(--navy-blue);
}

@media (min-width: 768px) {
  .c-top-menu__user {
    margin: 0 8px;
  }
}

.c-top-menu__locale {
  text-transform: uppercase;
  font-weight: 600;
}

.c-top-menu__locale:hover {
  color: var(--sky-blue);
}

.c-top-menu__locale:active {
  color: var(--navy-blue);
}

@media (min-width: 768px) {
  .c-top-menu__locale {
    border-radius: 8px 8px 0 0;
  }

  .c-top-menu__locale-options {
    border-top: 1px solid white;
  }
}

.is-opened .c-top-menu__locale-item {
  position: relative;
  display: block;
  height: auto;
  padding: 16px;
  line-height: 24px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  background-color: white;
}

@media (min-width: 768px) {
  .is-opened .c-top-menu__locale-item {
    border: none;
    box-sizing: border-box;
    width: 100%;
  }
}

.c-top-menu__dropdown-arrow {
  display: inline-block;
  margin-left: 8px;
  line-height: var(--line-height-tiny);
}

.c-top-menu__text-item {
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
}

.c-top-menu__link {
  margin: 0 8px;
  user-select: none;
}

@media (min-width: 768px) {
  .c-top-menu__link {
    margin: 0 12px;
  }

  .c-top-menu--internal .c-top-menu__link {
    margin: 0 16px;
  }
}

.c-top-menu__link .fa-bars {
  display: inline-block;
}

.c-top-menu__link .fa-xmark {
  display: none;
}

.is-opened .c-top-menu__link .fa-bars {
  display: none;
}

.is-opened .c-top-menu__link .fa-xmark {
  display: inline-block;
}

.c-top-menu__link--user {
  margin: 0;
  padding: 8px;
  height: 32px;
  width: 32px;
  font-size: 16px;
  color: white;
  background-color: var(--mid-blue);
  border-color: var(--mid-blue);
}

@media screen and (min-width: 768px) {
  .c-top-menu__link--user {
    line-height: 20px;
    height: 40px;
    width: auto;
  }
}

.c-top-menu__link--user:hover {
  background-color: var(--sky-blue);
  border-color: var(--sky-blue);
  color: white;
}

.c-top-menu__link--user:active {
  background-color: var(--sky-blue);
  border-color: var(--sky-blue);
  color: white;
}

.c-top-menu__link--user-icon {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .c-top-menu__link--user-icon {
    margin-right: 8px;
  }
}

.c-top-menu .fa-angle-up {
  display: none;
}

.c-top-menu .is-opened .fa-angle-up {
  display: inline;
}

.c-top-menu .is-opened .fa-angle-down {
  display: none;
}

.c-top-menu__count-notification {
  position: absolute;
  top: 21px;
  left: 175px;
}

.c-top-menu__subitem .c-top-menu__count-notification {
  /*subtract sum of the width (1.5em) and margin (8px) of icon from top-level menu item*/
  left: calc(175px - 8px - 1.5em);
}
