.c-video-consultation-cost {
  margin: 24px 0 0;
  padding: 24px 0;
  background-color: var(--navy-blue);
}

@media (min-width: 1200px) {
  .c-video-consultation-cost {
    margin: 32px 0 0;
    padding: 32px 0;
  }
}
