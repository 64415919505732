.s-layout-grid {
  min-height: 100vh;
  display: grid;
  grid-template:
    'header' auto
    'banner' auto
    'content' 1fr
    'footer' auto /
    1fr;
}

.s-layout-grid__main-content {
  grid-area: content;
}

.s-layout-grid__front {
  padding-bottom: 0;
}
