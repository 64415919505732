.c-video-consultation-intro {
  padding: 16px 0 24px;
}

@media (min-width: 768px) {
  .c-video-consultation-intro {
    padding: 24px 0;
  }
}

@media (min-width: 1200px) {
  .c-video-consultation-intro {
    padding: 40px 0 32px;
  }
}
