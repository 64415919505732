.c-user-data {
  display: block;
}

.c-user-data__column {
  width: 100%;
}

.c-user-data__address > div {
  margin-bottom: 32px;
}

.c-user-data__address > div:last-child {
  margin-bottom: 0;
}

.c-user-data__login-info {
  display: block;
  margin-bottom: 80px;
}

.c-user-data_profile-full-name {
  width: auto;
}

.c-user-data_profile {
  width: auto;
  margin-bottom: 32px;
}

.c-user-data__submit-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.c-user-data__spacer {
  position: relative;
  left: -8px;
  width: calc(100% + 16px); /* 16px are the paddings of container .f-dashboard__content*/
  height: 1px;
  background-color: var(--grey-90);
}

/*
 * a compromise to fit the old design
 * Adjust it when switching to the new breakpoints
 */
@media (min-width: 576px) {
  .c-user-data__spacer {
    left: -48px;
    width: calc(100% + 96px); /* 96px are the paddings of container .f-dashboard__content*/
  }
}

@media screen and (min-width: 768px) {
  .c-user-data {
    display: flex;
    justify-content: space-between;
  }

  .c-user-data__column {
    width: 45%;
  }

  .c-user-data_profile {
    margin-bottom: 0;
  }

  .c-user-data__login-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 88px;
  }
}

@media (min-width: 1024px) {
  .c-user-data__address > div {
    margin-bottom: 0;
  }

  .c-user-data__address > div:first-child {
    width: 40%;
  }
}
