.s-count-notification {
  display: inline-block;
  width: 17px;
  height: 17px;
  border-radius: 100%;
  line-height: 18px;
  text-align: center;
  font-size: var(--font-size-tiny);
  background-color: var(--grey-700);
  color: white;
  font-family: Courier, monospace;
}

.s-count-notification--light {
  color: var(--sky-blue);
  background-color: white;
}
