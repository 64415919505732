.f-user-area-menu {
  display: none;
  flex-shrink: 0;
  padding: var(--container-padding);
  width: 240px;
}

@media (min-width: 768px) {
  .f-user-area-menu {
    display: block;
  }
}

.f-user-area-menu__cta-button {
  display: flex;
  margin: 16px;
  height: 44px;
  font-size: 16px;
}

@media (min-width: 768px) {
  .f-user-area-menu__cta-button {
    margin: 0 16px 32px 16px;
  }
}

.f-user-area-menu__item {
  border-left: 4px solid transparent;
  padding-left: 16px;
}

.f-user-area-menu__item,
.f-user-area-menu__subitem {
  display: block;
  position: relative;
  height: 48px;
  line-height: 48px;
  color: var(--dark);
  font-weight: 600;
  text-decoration: none;
}

.f-user-area-menu__item:hover {
  background-color: var(--light-blue-background);
  border-color: var(--grey-700);
  text-decoration: none;
}

.f-user-area-menu__item--active {
  background-color: var(--light-grey-background);
  border-color: var(--dark);
}

.f-user-area-menu__count {
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
}

.f-user-area-menu__subitem {
  width: calc(100% + 4px);
  left: -4px; /* 4px left shift to reach the edge of parent container */
  padding-left: 50px; /* add extra 2 pt for vertial alignment with main manu item*/
  box-sizing: border-box;
  border-left: 4px solid transparent;
  color: var(--grey-800);
}

.f-user-area-menu__subitem--active {
  color: var(--dark);
}

.f-user-area-menu__subitem:hover {
  background-color: var(--light-blue-background);
  border-color: var(--grey-700);
  text-decoration: none;
}
