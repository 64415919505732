.f-profile {
  display: flex;
  align-items: center;
  width: 100%;
}

.f-profile__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  width: 28px;
  height: 28px;
  margin-right: 8px;
  border-radius: 50%;
  color: var(--dark);
  font-size: var(--font-size-small);
}

.f-profile__data {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
}

.f-profile__full-name {
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 16px);
}

.f-profile__full_name--slim {
  width: 90px;
}

.f-profile__avatar--large {
  width: 40px;
  height: 40px;
  font-size: var(--font-size-normal);
  line-height: var(--line-height-default);
}

.f-profile__avatar--bg-0 {
  background-color: var(--avatar-blue);
}

.f-profile__avatar--bg-1 {
  background-color: var(--avatar-lilac);
}

.f-profile__avatar--bg-2 {
  background-color: var(--avatar-green);
}

.f-profile__avatar--bg-3 {
  background-color: var(--avatar-red);
}

.f-profile__avatar--bg-4 {
  background-color: var(--avatar-orange);
}

.f-profile__avatar--bg-5 {
  background-color: var(--avatar-light-blue);
}

.f-profile__avatar--bg-6 {
  background-color: var(--avatar-yellow);
}
