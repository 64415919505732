.o-verify-qr-code {
  max-width: 600px;
  margin: 32px auto;
}

@media print {
  .o-verify-qr-code {
    max-width: 50%;
  }
}
