.c-video-consultation-find-appointment {
  margin: 24px 0 24px;
  padding: 24px 0;
  background-color: var(--sky-blue);
}

.c-video-consultation-find-appointment__button-group {
  display: flex;
  flex-direction: column;
}

.c-video-consultation-find-appointment__primary-action {
  margin-bottom: 16px;
}

.c-video-consultation-find-appointment__secondary-action {
  color: white;
  font-size: var(--font-size-small);
  line-height: var(--line-height-secondary);
}

.c-video-consultation-find-appointment__secondary-action:hover {
  color: white;
}

@media (min-width: 768px) {
  .c-video-consultation-find-appointment__button-group {
    flex-direction: row;
    align-items: center;
  }

  .c-video-consultation-find-appointment__primary-action {
    margin-right: 24px;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .c-video-consultation-find-appointment {
    margin: 32px 0 32px;
    padding: 32px 0;
  }
}
