.s-loading-spinner.is-active svg {
  animation: spin 1s infinite ease-in-out;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
