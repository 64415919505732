.s-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--mid-blue);
  border-radius: 5px;
  padding: 0 16px;
  height: 32px;
  font-size: var(--font-size-button);
  line-height: 32px;
  color: white;
  font-weight: 600;
  white-space: nowrap;
  background-color: var(--mid-blue);
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  letter-spacing: normal;
}

.s-button:hover {
  border: 1px solid var(--sky-blue);
  background-color: var(--sky-blue);
}

.s-button:active {
  border: 1px solid var(--navy-blue);
  background-color: var(--navy-blue);
}

.s-button:disabled,
.s-button--disabled {
  color: var(--grey-80);
  background-color: var(--grey-90);
  border: 1px solid var(--grey-90);
  cursor: default;
  pointer-events: none;
}

.s-button--full-width {
  width: 100%;
  justify-content: center;
}

.s-button--secondary,
.s-button--secondary:hover,
.s-button--secondary:active {
  border: none;
  color: var(--mid-blue);
  background-color: var(--blue1);
}

.s-button--secondary:hover {
  background-color: var(--blue2);
}

.s-button--secondary:active {
  background-color: var(--sky-blue);
}

.s-button--secondary-transparent {
  border: 1px solid white;
  color: white;
  background-color: transparent;
}

.s-button--secondary-transparent:hover {
  border-color: white;
  color: var(--sky-blue);
  background-color: white;
}

.s-button--secondary-transparent:active {
  border-color: var(--navy-blue);
  color: var(--navy-blue);
  background-color: transparent;
}

.s-button--secondary-transparent:disabled {
  border-color: var(--grey-80);
  color: var(--grey-80);
  background-color: transparent;
}

.s-button--danger {
  color: white;
  border: var(--pink);
  background-color: var(--pink);
}

.s-button--danger:hover {
  color: white;
  border: var(--light-pink);
  background-color: var(--light-pink);
}

.s-button--danger:active {
  color: white;
  border: var(--dark-pink);
  background-color: var(--dark-pink);
}

.s-button--danger-secondary {
  color: var(--pink);
  border-color: var(--grey-90);
  background-color: white;
}

.s-button--danger-secondary:hover {
  color: var(--light-pink);
  border-color: var(--grey-90);
  background-color: white;
}

.s-button--danger-secondary:active {
  color: var(--dark-pink);
  border-color: var(--grey-90);
  background-color: white;
}

.s-button--video {
  color: white;
  border: var(--orange);
  background-color: var(--orange);
}

.s-button--video:hover {
  color: white;
  border: var(--light-orange);
  background-color: var(--light-orange);
}

.s-button--video:active {
  color: white;
  border: var(--dark-orange);
  background-color: var(--dark-orange);
}

.s-button__icon {
  position: relative;
  top: 3px;
  margin: -6px 8px 0 0;
  font-size: 16px;
}

.s-button__icon--no-offset {
  top: 0px;
}

.s-button--borderless,
.s-button--borderless:hover,
.s-button--borderless:active {
  border: none;
}

.s-button--large {
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
}

.s-button--medium {
  height: 40px;
  line-height: 40px;
  padding: 0 32px;
  font-size: 16px;
}

.s-button--large-with-icon {
  padding: 0 24px;
}

.s-button--medium-with-icon {
  padding: 0 24px;
}
