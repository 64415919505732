.o-notification {
  display: none;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 3px;
  font-size: var(--font-size-secondary);
  line-height: var(--line-height-secondary);
  color: white;
  background-color: var(--sky-blue);
}

.o-notification.is-toggle-active {
  display: flex;
}

.o-notification--notice,
.o-notification--info {
  color: var(--grey-30);
  background-color: var(--light-baby-blue);
}

.o-notification--error,
.o-notification--alert {
  color: var(--grey-30);
  background-color: var(--light-baby-pink);
}

.o-notification--warning {
  color: var(--grey-30);
  background-color: var(--beeswax-orange);
}

.o-notification__close {
  font-size: 18px;
  margin-left: 16px;
  flex-shrink: 0;
  color: var(--grey-30);
  cursor: pointer;
}
