@media (min-width: 768px) {
  .o-dropdown {
    position: relative;
  }
}

.o-dropdown__switch {
  border: 1px solid transparent;
}

.is-opened .o-dropdown__switch {
  background-color: var(--light-blue-background);
  color: var(--dark);
}

.is-opened .o-dropdown__switch--grey-bg {
  position: relative;
  margin-top: 6px;
  padding: 8px 0 14px 0;
  border-radius: 5px 5px 0px 0px;
  background-color: var(--grey-96);
  border-color: var(--grey-90);
}

.is-opened .o-dropdown__switch--grey-bg::after {
  content: '';
  height: 10px;
  background-color: var(--grey-96);
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  z-index: 15;
}

.o-dropdown__content {
  position: absolute;
  right: 0;
  left: 0;
  padding-bottom: 8px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-top: 1px solid #e6e6e6;
  border-radius: 0 0 8px 8px;
  min-width: 375px;
  /* To be on the top, the z-index value must be larger than 3,
   * which an active pagination link has as default.
   */
  z-index: 4;
}

@media (min-width: 768px) {
  .o-dropdown__content {
    box-sizing: border-box;
    left: auto;
    min-width: 100%;
    background: none;
  }
}
