.o-vertical-stepper__item {
  position: relative;
  min-height: 3em;
  margin: 0;
  padding: 0;
  border: 0;
}

.o-vertical-stepper__line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 22px;
  width: 5px;
  background: var(--sun);
  z-index: 0;
}

.o-vertical-stepper__number {
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 0 0 0 8px;
  border-radius: 100%;
  font-size: var(--font-size-medium);
  font-weight: bold;
  line-height: 32px;
  background: var(--sun);
  color: white;
  text-align: center;
  line-height: 32px;
}

.o-vertical-stepper__text {
  position: relative;
  top: -4px;
  border: 0;
  margin-left: 88px;
}
