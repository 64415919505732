@import '../../shared/loading_spinner.css';

.c-search-result {
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow:
    0px 3px 4px rgba(0, 0, 0, 0.04),
    0px 1.94444px 2.34259px rgba(0, 0, 0, 0.0303704),
    0px 1.15556px 1.27407px rgba(0, 0, 0, 0.0242963),
    0px 0.6px 0.65px rgba(0, 0, 0, 0.02),
    0px 0.244444px 0.325926px rgba(0, 0, 0, 0.0157037),
    0px 0.0555556px 0.157407px rgba(0, 0, 0, 0.00962963);
  background-color: var(--light);
}

.c-search-result:nth-child(even) {
  background-color: var(--grey-98);
}

.c-search-result__content-header {
  padding: 24px 16px;
}

.c-search-result__healer {
  padding: 16px 8px 8px;
  border-top: 2px solid var(--grey-96);
}

@media (min-width: 768px) {
  .c-search-result__healer {
    padding: 16px 12px 12px;
  }
}

@media (min-width: 1024px) {
  .c-search-result__healer {
    padding: 24px 16px;
  }
}

.c-search-result__healer-info {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .c-search-result__healer-info {
    margin-bottom: 12px;
  }
}

@media (min-width: 1024px) {
  .c-search-result__healer-info {
    margin-bottom: 16px;
  }
}

.c-search-result__healer-name-and-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.c-search-result__healer-field {
  margin: 0;
  font-size: var(--font-size-small);
  color: var(--grey-700);
}

.c-search-result__photo-frame {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border: 1px solid var(--grey-90);
  border-radius: 50%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .c-search-result__photo-frame {
    margin-right: 12px;
  }
}

@media (min-width: 1024px) {
  .c-search-result__photo-frame {
    margin-right: 16px;
  }
}

.c-search-result__photo {
  max-height: 100%;
}

.c-search-result__event-types-list {
  margin-left: 0;
}

@media (min-width: 1024px) {
  .c-search-result__event-types-list {
    margin-left: 56px;
  }
}

.c-search-result__event-type {
  display: none;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  padding: 16px 0;
  border-top: 1px solid var(--grey-95);
  color: var(--grey-40);
}

.c-search-result__event-type-wrapper {
  display: none;
  padding: 16px 0;
  border-top: 1px solid var(--grey-96);
}

.c-search-result__event-type:first-child {
  border-top: none;
}

.c-search-result__event-type:nth-child(-n + 3),
.is-toggle-active .c-search-result__event-type {
  display: flex;
}

.c-search-result__event-type-wrapper:nth-child(-n + 3),
.is-toggle-active .c-search-result__event-type-wrapper {
  display: block;
}

@media (max-width: 576px) {
  .c-search-result__event-type {
    flex-direction: column;
    align-items: stretch;
  }

  .c-search-result__event-type--inline {
    flex-direction: row;
  }
}

.c-search-result__event-type-title {
  padding-right: 16px;
  word-break: break-word;
}

@media (min-width: 576px) {
  .c-search-result__event-type-title {
    padding-right: 24px;
    word-break: normal;
  }
}

.c-search-result__tag {
  margin-left: 8px;
  height: 24px;
  border-radius: 3px;
  padding: 0 8px;
  font-size: 16px;
  color: var(--orange);
}

.c-search-result__show-more-button,
.c-search-result__show-less-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 100%;
  line-height: 48px;
  padding: 0px 8px;
  color: var(--mid-blue);
  cursor: pointer;
  box-sizing: border-box;
  border-top: 1px solid var(--grey-96);
  border-radius: 0px 0px 8px 8px;
}

@media (min-width: 768px) {
  .c-search-result__show-more-button,
  .c-search-result__show-less-button {
    justify-content: flex-start;
  }
}

.c-search-result__show-more-button:hover,
.c-search-result__show-more-button:active,
.c-search-result__show-more-button:focus,
.c-search-result__show-less-button:hover,
.c-search-result__show-less-button:active,
.c-search-result__show-less-button:focus {
  background-color: var(--light-blue-background);
}

.is-toggle-active .c-search-result__show-more-button {
  display: none;
}

.c-search-result__show-less-button {
  display: none;
}

.is-toggle-active .c-search-result__show-less-button {
  display: flex;
}
