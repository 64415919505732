.c-toast {
  width: 90%;
  max-width: 350px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid var(--grey-95);
  border-left: 8px solid var(--mid-blue);
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: #fff;
  z-index: 5;
  box-sizing: border-box;
  padding: 16px;
}

.c-toast--error {
  border-left: 8px solid var(--pink);
}

.c-toast-heading {
  color: var(--mid-blue);
  font-size: var(--font-size-h6);
  line-height: var(--line-height-h6);
}

.c-toast-heading--error {
  color: var(--pink);
}

.c-toast-button {
  font-size: var(--font-size-h6);
  background-color: var(--mid-blue);
  font-weight: 600;
  color: #fff;
  padding: 4px 16px;
  border: 0 solid transparent;
  border-radius: 5px;
  cursor: pointer;
}

.c-toast-content {
  font-size: var(--font-size-secondary);
  line-height: var(--line-height-tiny);
  color: var(--grey-40);
}
