.s-button-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.s-button-group--left-aligned {
  justify-content: flex-start;
  flex-direction: column;
}

.s-button-group--side-by-side {
  flex-direction: row;
}

.s-button-group--full-width {
  width: 100%;
}

.s-button-group__button {
  margin: 0 0 8px 0;
  text-align: center;
}

.s-button-group__button:last-child {
  margin: 0;
}

@media (min-width: 576px) {
  .s-button-group__button {
    margin: 0 8px 0 0;
  }

  .s-button-group {
    flex-direction: row;
  }
}
