.f-faq-page__video {
  width: 100%;
  height: auto;
}

.f-faq-page__image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 274px;
}

@media (min-width: 768px) {
  .f-faq-page__image {
    width: 216px;
    margin-top: 160px;
  }
}

@media (min-width: 1024px) {
  .f-faq-page__image {
    width: 274px;
    margin-top: 176px;
  }
}
