h1 {
  margin-bottom: 0;
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1);
  letter-spacing: var(--letter-spacing-h1);
  font-weight: 600;
  color: var(--dark);
}

h1 + h2 {
  margin-top: 24px;
}

h1 + h3 {
  margin-top: 16px;
}

h1 + h4 {
  margin-top: 16px;
}

h1 + h5 {
  margin-top: 8px;
  letter-spacing: var(--letter-spacing-h5);
  font-weight: 400;
}

h1 + p,
h1 + div {
  margin-top: 32px;
}

.h1--default-margin {
  margin-bottom: 32px;
}

h2 {
  margin-bottom: 0;
  font-size: var(--font-size-h2);
  line-height: var(--line-height-h2);
  letter-spacing: var(--letter-spacing-h2);
  font-weight: 600;
  color: var(--dark);
}

h2 + h3 {
  margin-top: 16px;
}

h2 + h4 {
  margin-top: 8px;
}

h2 + h5 {
  margin-top: 16px;
}

h2 + p,
h2 + div {
  margin-top: 24px;
}

.h2--default-margin {
  margin-bottom: 24px;
}

h3 {
  margin-bottom: 0;
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  letter-spacing: var(--letter-spacing-h3);
  font-weight: 600;
  color: var(--dark);
}

h3 + h4 {
  margin-top: 8px;
}

h3 + h5 {
  margin-top: 8px;
}

h3 + p,
h3 + div {
  margin-top: 24px;
}

.h3--default-margin {
  margin-bottom: 24px;
}

h4 {
  margin-bottom: 0;
  font-size: var(--font-size-h4);
  line-height: var(--line-height-h4);
  letter-spacing: var(--letter-spacing-h4);
  font-weight: 400;
  color: var(--grey-700);
}

h4 + h5 {
  margin-top: 8px;
}

h4 + p,
h4 + div {
  margin-top: 16px;
}

.h4--default-margin {
  margin-bottom: 16px;
}

.h4--alternative {
  font-weight: 300;
}

h5 {
  margin-bottom: 0;
  font-size: var(--font-size-h5);
  line-height: var(--line-height-h5);
  letter-spacing: var(--letter-spacing-h5);
  font-weight: 600;
  color: var(--dark);
}

h5 + p,
h5 + div {
  margin-top: 16px;
}

.h5--default-margin {
  margin-bottom: 16px;
}

.h5--alternative {
  font-weight: 400;
}

h6 {
  margin-bottom: 8px;
  font-size: var(--font-size-h6);
  line-height: var(--line-height-h6);
  font-weight: 700;
}

@media (min-width: 768px) {
  h1 + h3 {
    margin-top: 24px;
  }

  h3 + h5 {
    margin-top: 16px;
  }
}

@media (min-width: 1200px) {
  h1 + p,
  h1 + div {
    margin-top: 40px;
  }

  .h1--default-margin {
    margin-bottom: 40px;
  }

  h2 + h3 {
    margin-top: 24px;
  }

  h2 + h4 {
    margin-top: 16px;
  }

  h2 + h5 {
    margin-top: 8px;
  }

  h2 + p,
  h2 + div {
    margin-top: 32px;
  }

  .h2--default-margin {
    margin-bottom: 32px;
  }

  h3 + h5 {
    margin-top: 8px;
  }

  h4 + h5 {
    margin-top: 0;
  }
}
