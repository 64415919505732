.f-admin__user-label {
  padding: 2px 8px;
  border-radius: 3px;
  font-size: var(--font-size-secondary);
  font-weight: bold;
  color: white;
}

.f-admin__user-label--dpo {
  background-color: var(--orange);
}

.f-admin__user-label--support {
  background-color: var(--sky-blue);
}

.f-admin__user-label--admin {
  background-color: var(--mid-blue);
}

.f-admin__text-field {
  vertical-align: sub;
}

.f-admin__text-field-40 {
  width: 40%;
}

.f-admin__row-vertical-align-top {
  vertical-align: top;
}

.f-admin__admins-list {
  padding-left: 0;
}

.f-admin__admins-list-item {
  list-style-type: none;
}

.f-admin__form__text-field {
  border: 1px solid var(--grey-90);
}

.f-admin__form__select {
  border: 1px solid var(--grey-90);
  background: #fff;
  padding: 0.1rem 0.5rem;
}

.f-admin-forms__action-button {
  font-size: inherit;
  white-space: nowrap;
  border: 1px solid var(--grey-90);
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 1rem;
}

.active {
  background-color: var(--mid-blue);
  color: white;
  padding: 0.2rem 0.5rem;
}
