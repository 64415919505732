.f-form-attachments {
  width: 100%;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.f-form-attachments__actions-wrapper {
  display: flex;
  min-width: 125px;
}

.f-form-attachments__abort-link {
  display: none;
  color: #0067b2;
  text-decoration: none;
  padding: 5px 5px 5px 0;
  font-size: 12px;
  line-height: 1.2;
}

@media screen and (min-width: 400px) {
  .f-form-attachments__abort-link {
    display: inline;
  }
}

@media screen and (min-width: 600px) {
  .f-form-attachments__abort-link {
    font-size: 14px;
    padding: 5px 10px;
  }
}

.f-form-attachments__abort-icon {
  display: inline-block;
  font-size: 13px;
  padding: 5px 8px;
  line-height: 1.4;
}

@media screen and (min-width: 600px) {
  .f-form-attachments__abort-icon {
    font-size: 16px;
  }
}

.f-form-attachments__submit {
  color: white;
  cursor: pointer;
  border: 0 solid transparent;
  background-color: #0067b2;
  font-size: 12px;
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
}

@media screen and (min-width: 600px) {
  .f-form-attachments__submit {
    font-size: 14px;
    padding: 0 16px;
  }
}

.f-form-attachments__area {
  position: relative;
}

.f-form-attachments__area--is-deactivated {
  pointer-events: none;
}

.f-form-attachments__area--is-activated {
  pointer-events: auto;
}

.f-form-attachments__footer-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.f-form-attachments__loading-wrapper {
  position: absolute;
  bottom: 58px;
  left: 7px;
  z-index: 2;
  width: 95%;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  overflow: hidden;
  padding: 16px 16px 16px 24px;
  box-sizing: border-box;
  max-width: 350px;
}

.f-form-attachments__loading-wrapper:before {
  width: 8px;
  height: 100%;
  content: ' ';
  background-color: var(--sky-blue);
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.f-form-attachments__loading-heading {
  color: var(--sky-blue);
  font-size: var(--font-size-h6);
  line-height: var(--line-height-h6);
}

.f-form-attachments__loading-bar {
  width: 100%;
  background-color: var(--grey-80);
  height: 3px;
  margin: 6px 0;
  padding: 0;
  display: flex;
}

.f-form-attachments__loading-bar-progress {
  height: 3px;
  background-color: var(--mid-blue);
  display: inline-block;
  margin: 0;
  padding: 0;
}

.f-form-attachments__loading-status {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.f-form-attachments__loading-file-size {
  color: var(--grey-40);
  font-size: var(--font-size-secondary);
  line-height: var(--line-height-h6);
  margin: 0;
}

.f-form-attachments__loading-percentage {
  color: var(--mid-blue);
  font-size: var(--font-size-h6);
  line-height: var(--line-height-h6);
  font-weight: bold;
  margin: 0;
}

.f-form-attachments__loading-max-size {
  font-size: var(--font-size-tiny);
  color: var(--grey-60);
}

.f-form-attachments__loading-warning {
  font-size: var(--font-size-secondary);
  color: var(--grey-60);
  line-height: var(--line-height-tiny);
  margin: 0;
}

.f-form-attachments__total-files {
  color: #0067b2;
  text-decoration: none;
  padding: 5px 5px 5px 0;
  font-size: 12px;
  line-height: 1.2;
  height: 40px;
  border-left: 0 solid transparent;
  border-right: 1px solid #e6e6e6;
  box-shadow: 0px 0px 3px transparent;
  position: relative;
  cursor: pointer;
}

.f-form-attachments__total-files--is-attachments-table-visible {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.f-form-attachments__total-files:before {
  content: ' ';
  width: 100%;
  display: none;
  background-color: #fff;
  height: 6px;
  position: absolute;
  top: -2px;
  left: 0;
}

.f-form-attachments__total-files--is-attachments-table-visible:before {
  display: inline-block;
}

@media screen and (min-width: 600px) {
  .f-form-attachments__total-files {
    font-size: 14px;
  }
}

.f-form-attachments__info-modal {
  color: #0067b2;
  text-decoration: none;
  padding: 5px 5px 5px 0;
  font-size: 11px;
  line-height: 1.2;
  height: 40px;
  box-shadow: 0px 0px 3px transparent;
  position: relative;
  cursor: pointer;
  flex-grow: 4;
  text-align: center;
}

.f-form-attachments__info-modal-text {
  display: none;
  margin: 0.7em;
}

@media screen and (min-width: 550px) {
  .f-form-attachments__info-modal-text {
    display: inline;
    font-size: 14px;
  }
}

.f-form-attachments__footer-total-files-text {
  display: none;
  margin: 0.7em;
}

@media screen and (min-width: 850px) {
  .f-form-attachments__footer-total-files-text {
    font-size: 14px;
    margin: 0;
  }
}

.f-form-attachments__footer-icon {
  display: inline-block;
  font-size: 13px;
  padding: 5px 8px;
  line-height: 1.4;
}

@media screen and (min-width: 600px) {
  .f-form-attachments__footer-icon {
    font-size: 16px;
  }
}

.f-form-attachments__upload-button {
  color: #0067b2;
  cursor: pointer;
  border: none;
  border-right: 1px solid #e6e6e6;
  background-color: white;
  font-size: 11px;
  height: 40px;
}

.f-form-attachments__footer-upload-files-text {
  display: none;
  margin: 0.7em;
}

@media screen and (min-width: 850px) {
  .f-form-attachments__footer-upload-files-text {
    display: inline;
    font-size: 14px;
    margin: 0;
  }
}

.f-form-attachments__save-draft-button {
  color: #0067b2;
  cursor: pointer;
  border: none;
  background-color: white;
  font-size: 11px;
  height: 40px;
  padding-left: 8px;
  padding-right: 8px;
}

@media screen and (min-width: 600px) {
  .f-form-attachments__save-draft-button {
    font-size: 14px;
  }
}

@media screen and (min-width: 600px) {
  .f-form-attachments__upload-button {
    font-size: 14px;
  }
}

.f-form-attachments__upload-button-icon {
  display: inline-block;
  font-size: 13px;
  padding: 5px 8px;
}

@media screen and (min-width: 600px) {
  .f-form-attachments__upload-button-icon {
    font-size: 16px;
  }
}

.f-form-attachments__attachment-list {
  max-width: 550px;
  background-color: white;
  bottom: 40px;
  color: #4d4d4d;
  font-size: 14px;
  left: 0;
  position: fixed;
  width: 100%;
  padding: 0;
  margin: 0;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  overflow: auto;
  max-height: 50%;
}

@media screen and (min-width: 600px) {
  .f-form-attachments__attachment-list {
    margin: 0 16px;
  }
}

.f-form-attachments__attachment-list-item {
  width: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #f2f2f2;
  justify-content: space-between;
}

@media screen and (min-width: 600px) {
  .f-form-attachments__attachment-list-item {
    height: 56px;
    line-height: 56px;
  }
}

.f-form-attachments__attachment-data {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 8px;
  height: 20px;
  line-height: 1.4;
  width: 75%;
}

@media screen and (min-width: 550px) {
  .f-form-attachments__attachment-data {
    width: 80%;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 600px) {
  .f-form-attachments__attachment-data {
    padding: 0 0 0 16px;
    height: 56px;
    line-height: 56px;
  }
}

.f-form-attachments__attachment-file-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 70%;
}

.f-form-attachments__attachment-action-buttons-wrapper {
  margin-right: 20px;
}

.f-form-attachments__attachment-action-button {
  background-color: transparent;
  border: none;
  color: #0067b2;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 8px 4px;
  text-decoration: none;
  margin-left: 2px;
}

.f-form-attachments__attachment-close-wrapper {
  width: 100%;
  text-align: right;
}

.f-form-attachments__attachment-close {
  color: #4d4d4d;
  font-size: 13px;
  display: inline-block;
  cursor: pointer;
  padding: 12px 20px 12px 0;
  border: 0 solid transparent;
  background: transparent;
}

@media screen and (min-width: 600px) {
  .f-form-attachments__attachment-close {
    padding: 0 20px 12px 0;
  }
}

.f-form-attachments__attachment-close-icon {
  padding: 0 0 0 8px;
}

.f-form-attachments__error {
  background: var(--light-baby-pink);
  border-radius: 3px;
  box-sizing: border-box;
  padding: 16px;
  width: 90%;
  margin: 5%;
  position: fixed;
  top: 40px;
  left: 0;
  display: flex;
  justify-content: space-between;
}

.f-form-attachments__error-message {
  font-size: var(--font-size-secondary);
  color: var(--grey-30);
  margin: 0;
}

.f-form-attachments__error-close {
  color: var(--grey-40);
  cursor: pointer;
}
