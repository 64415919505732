.o-two-column-page {
  display: grid;
  grid-template:
    'main-content' auto
    'side-content' auto /
    12fr;
  min-height: 100%;
  color: var(--grey-40);
}

@media (min-width: 768px) {
  .o-two-column-page {
    grid-template:
      'main-content gap side-content' auto /
      7fr 1fr 4fr;
  }
}

.o-two-column-page__main-content {
  grid-area: main-content;
  margin-bottom: var(--grid-margin);
}

.o-two-column-page__gap {
  grid-area: gap;
}

@media (min-width: 768px) {
  .o-two-column-page__gap {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.o-two-column-page__side-content {
  grid-area: side-content;
  margin-bottom: var(--grid-margin);
}

.o-two-column-page__side-content-title {
  margin-bottom: 24px;
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  letter-spacing: var(--letter-spacing-h3);
  font-weight: 600;
  color: var(--dark);
}

.o-two-column-page__main-content-teaser {
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 24px;
  color: var(--dark);
  font-weight: 400;
  letter-spacing: 0;
}
