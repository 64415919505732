.c-hero-search {
  flex-direction: column;
  margin-bottom: 32px;
}

.c-hero-field__search-box {
  display: grid;
  grid-template:
    'speciality-field' auto
    'location-field' auto
    'video-consultation-checkbox' auto /
    12fr;
  max-width: 1200px;
  margin: auto;
  border: 1px solid var(--grey-90);
  background-color: white;
  border-radius: 8px 8px 0 0;
  box-shadow:
    0px 32px 24px rgba(0, 0, 0, 0.04),
    0px 20.7407px 14.0556px rgba(0, 0, 0, 0.0303704),
    0px 12.3259px 7.64444px rgba(0, 0, 0, 0.0242963),
    0px 6.4px 3.9px rgba(0, 0, 0, 0.02),
    0px 2.60741px 1.95556px rgba(0, 0, 0, 0.0157037),
    0px 0.592593px 0.944444px rgba(0, 0, 0, 0.00962963);
}

.c-hero-search__field-set {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey-95);
  padding: 20px 16px;
  box-sizing: border-box;
}

.c-hero-field__search-box--plain {
  border: none;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}

.c-hero-search__field-speciality {
  padding-left: 0;
  grid-area: speciality-field;
}

.c-hero-search__field-location {
  padding-left: 0;
  grid-area: location-field;
}

.c-hero-search__video-consultations {
  grid-area: video-consultation-checkbox;
  border: none;
}

.c-hero-search__more-info {
  grid-area: initial;
  color: var(--mid-blue);
  text-align: left;
  border: none;
}

.c-hero-search__field-icon-container {
  display: inline-block;
  text-align: center;
  width: 56px;
}

.c-hero-search__field-icon {
  font-size: var(--font-size-large);
  color: var(--dark);
}

.c-hero-search__field-box {
  height: 32px;
  width: 100%;
}

.c-hero-search__label {
  display: block;
  color: var(--dark);
  font-weight: bold;
  font-size: var(--font-size-secondary);
  line-height: var(--line-height-secondary);
}

.c-hero-search__sublabel,
.c-hero-search__location {
  font-size: var(--font-size-default);
}

.c-hero-search__sublabel {
  display: block;
  color: var(--dark);
}

.c-hero-search__location {
  border-bottom: 1px solid transparent;
  padding: 0;
  line-height: var(--line-height-default);
  font-weight: normal;
}

.c-hero-search__location:hover,
.c-hero-search__location:focus {
  border-color: var(--mid-blue);
}

.c-hero-search__inline-button {
  display: none;
}

.c-hero-search__block-button {
  display: block;
  font-size: 18px;
  width: 100%;
  text-align: center;
  border-radius: 0 0 8px 8px;
}

.c-hero-search__more-info-icon {
  margin-right: 8px;
  font-size: var(--font-size-default);
}

.c-hero-search__more-info-link {
  display: inline-flex;
  align-items: center;
  border: none;
  padding: 16px 16px 16px 0;
}

.c-hero__title,
.c-hero__description {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .c-hero-search {
    margin-bottom: 40px;
  }
}

@media (min-width: 960px) {
  .c-hero-search {
    flex-direction: row;
    margin-bottom: 64px;
  }

  .c-hero-search__field-set {
    border-bottom: none;
    border-left: 1px solid var(--grey-95);
  }

  .c-hero-field__search-box {
    grid-template:
      'speciality-field location-field video-consultation-checkbox' auto
      'indent indent more-info' auto /
      1fr 1fr auto;
    border-radius: 8px;
  }

  .c-hero-search__field-speciality {
    padding-left: 16px;
    border: none;
  }

  .c-hero-search__field-location {
    padding-left: 16px;
  }

  .c-hero-search__field-icon-container {
    width: auto;
    margin-right: 24px;
  }

  .c-hero-search__block-button {
    display: none;
  }

  .c-hero-search__inline-button {
    display: block;
    width: 40px;
    height: 40px;
    margin-left: 32px;
    padding: 0;
    font-size: var(--font-size-large);
  }

  .c-hero-search__more-info-link {
    padding: 8px 16px;
  }

  .c-hero-search__more-info {
    grid-area: more-info;
    text-align: right;
    font-size: var(--font-size-secondary);
  }
}

@media (min-width: 1312px) {
  .c-hero-search {
    margin-bottom: 80px;
  }

  .c-hero-search__inline-button {
    width: 48px;
    height: 48px;
    margin-left: 40px;
    border-radius: 8px;
    padding: 0;
  }

  .c-hero-search__field-icon {
    font-size: 32px;
  }

  .c-hero-search__label {
    font-size: var(--font-size-default);
    line-height: var(--line-height-default);
  }

  .c-hero-search__sublabel,
  .c-hero-search__location {
    font-size: var(--font-size-medium);
  }

  .c-hero-search__field-box {
    height: 56px;
  }
}
