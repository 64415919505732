@import './reset.css';
@import './tailwind.css';

/* Reusable CSS styles used across whole layout */
@import './objects/clickable.css';
@import './objects/container.css';
@import './objects/dropdown.css';
@import './objects/forms.css';
@import './objects/headers.css';
@import './objects/icon_line.css';
@import './objects/menu.css';
@import './objects/notch.css';
@import './objects/notification.css';
@import './objects/side_push.css';
@import './objects/tags.css';
@import './objects/vertical_separator.css';
@import './objects/switch.css';
@import './objects/secondary_text.css';
@import './objects/two_column_page.css';
@import './objects/table.css';
@import './objects/nested_menu.css';
@import './objects/select_box.css';
@import './objects/context_container.css';
@import './objects/checkbox.css';
@import './objects/vertical_stepper.css';
@import './objects/verify_qr_code.css';
@import './objects/list.css';
@import './shared/layout_grid.css';
@import './shared/count_notification.css';
@import './shared/toggle.css';
@import './shared/form_group.css';
@import './shared/button_group.css';
@import './shared/tooltip.css';
@import './shared/modal.css';
@import './shared/button.css';
@import './shared/video_container.css';
@import './shared/patient_events_modal.css';
@import './shared/a11y.css';

/* Single-use CSS styles, used on specific pages */
@import './components/homepage/hero.css';
@import './components/homepage/hero_search.css';
@import './components/homepage/points_showcase.css';
@import './components/search_results/search_result.css';
@import './components/search_results/search_form.css';
@import './components/search_results/map_popup.css';
@import './components/search_results/map.css';
@import './components/search_results/page.css';
@import './components/search_results/actions.css';
@import './components/video_consultation_landing_page/video_consultation_intro.css';
@import './components/video_consultation_landing_page/video_consultation_advantages.css';
@import './components/video_consultation_landing_page/video_consultation_how_it_works.css';
@import './components/video_consultation_landing_page/video_consultation_find_appointment.css';
@import './components/video_consultation_landing_page/video_consultation_additional_help.css';
@import './components/video_consultation_landing_page/video_consultation_cost.css';
@import './components/header.css';
@import './components/footer.css';
@import './components/password_reset.css';
@import './components/top_menu.css';
@import './components/login.css';
@import './components/registration.css';
@import './components/react_phone_number_input_theme.css';
@import './components/password_requirements.css';
@import './components/current_user.css';
@import './components/medical_record_entries.css';
@import './components/send_entry_modal.css';
@import './components/video_consultation_notification.css';
@import './components/asap_list.css';
@import './components/patient_plus_banner.css';
@import './components/toast.css';
@import './features/user_area_menu.css';
@import './features/error_page.css';
@import './features/back_to_top.css';
@import './features/internet_explorer_user.css';
@import './features/faq_page.css';
@import './features/faq.css';
@import './features/patient_events.css';
@import './features/admin.css';
@import './features/form_attachments.css';
@import './features/estimated_waiting_time.css';
@import './components/profile.css';
@import './components/user_data.css';

/* CSS helpers */
@import './helpers.css';

/* fonts */
@import './fonts.css';

/* Configuration */
@import './variables.css';
