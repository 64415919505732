.f-estimated-waiting-time {
  background-color: #fff;
  border: 1px solid var(--grey-90);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 32px 8px 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.f-estimated-waiting-time__last-updated {
  font-size: 11px;
  color: var(--grey-80);
  display: flex;
  justify-content: center;
}

.f-estimated-waiting-time__picture {
  width: calc(100% + 16px);
  max-width: 450px;
  align-self: center;
  margin-bottom: 8px;
}

.f-estimated-waiting-time__picture--too-early {
  width: 50%;
  max-width: 150px;
  margin: 8px auto;
}

.f-estimated-waiting-time__timer-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
  position: relative;
}

.f-estimated-waiting-time__countdown {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: var(--mid-blue);
  font-size: 30px;
  line-height: 32px;
  text-align: center;
  font-weight: 300;
  margin-top: 33px;
}

.f-estimated-waiting-time__countdown-units {
  font-size: 11px;
  color: var(--grey-60);
  display: flex;
  justify-content: center;
}

.f-estimated-waiting-time__countdown-hour {
  margin-right: 10px;
}
