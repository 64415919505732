.c-password-reset__side-image {
  display: block;
  width: 240px;
}

.user_accept_password_change_consequences label {
  display: block;
}

@media (min-width: 768px) {
  .c-password-reset__side-image {
    width: 216px;
  }
}
