.s-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(19, 19, 19, 0.31);
}

.s-modal--zero-opacity {
  opacity: 0;
}

.s-modal__content {
  position: relative;
  overflow-y: auto;
  margin: 16px;
  min-width: auto;
  max-width: 700px;
  max-height: calc(100vh - 96px);
  border-radius: 5px;
  padding: 40px 16px 24px;
  background-color: white;
  color: var(--grey-40);
}

@media (min-width: 768px) {
  .s-modal__content {
    position: relative;
    min-width: 500px;
    margin: 0 8px;
    padding: 40px 32px 24px;
  }
}

.s-modal__button:last-child {
  margin-bottom: 24px;
}

.s-modal__close-button {
  position: absolute;
  top: 16px;
  right: 24px;
  color: var(--grey-40);
  cursor: pointer;
}

.s-modal__button-set {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}
