.c-points-showcase {
  background-color: var(--light-blue-background);
  font-weight: 300;
}

.c-points-showcase__grid {
  display: grid;
  grid-template:
    'why-samedi           why-samedi' auto
    'claim-secure-icon    claim-secure-text' auto
    'claim-secure-icon    claim-secure-description' auto
    'claim-practical-icon claim-practical-text' auto
    'claim-practical-icon claim-practical-description' auto
    'claim-organized-icon claim-organized-text' auto
    'claim-organized-icon claim-organized-description' auto /
    2fr 10fr;
}

.c-points-showcase__description {
  margin-bottom: 48px;
  font-weight: 400;
}

.c-points-showcase__why-samedi {
  grid-area: why-samedi;
  margin-bottom: 24px;
}

.c-points-showcase__icon--secure {
  grid-area: claim-secure-icon;
}

.c-points-showcase__icon-title--secure {
  grid-area: claim-secure-text;
}

.c-points-showcase__description--secure {
  grid-area: claim-secure-description;
}

.c-points-showcase__icon--practical {
  grid-area: claim-practical-icon;
}

.c-points-showcase__icon-title--practical {
  grid-area: claim-practical-text;
}

.c-points-showcase__description--practical {
  grid-area: claim-practical-description;
}

.c-points-showcase__icon--organized {
  grid-area: claim-organized-icon;
}

.c-points-showcase__icon-title--organized {
  grid-area: claim-organized-text;
}

.c-points-showcase__description--organized {
  grid-area: claim-organized-description;
  margin-bottom: 0;
}

.c-points-showcase__icon {
  padding-top: 12px;
  text-align: center;
}

.c-points-showcase .fa-alarm-clock {
  width: 11px;
  bottom: 4px;
}

@media (min-width: 768px) {
  .c-points-showcase__grid {
    grid-template:
      'why-samedi why-samedi why-samedi' auto
      'claim-secure-icon claim-secure-text claim-secure-description' auto
      'claim-practical-icon claim-practical-text claim-practical-description' auto
      'claim-organized-icon claim-organized-text claim-organized-description' auto /
      2fr 3fr 7fr;
  }

  .c-points-showcase__sub-heading {
    margin-bottom: 72px;
  }

  .c-points-showcase__description {
    margin-top: 0;
    margin-bottom: 64px;
  }
}

@media (min-width: 1024px) {
  .c-points-showcase__grid {
    grid-template:
      'why-samedi claim-secure-icon claim-secure-text claim-secure-description' auto
      'why-samedi claim-practical-icon claim-practical-text claim-practical-description' auto
      'why-samedi claim-organized-icon claim-organized-text claim-organized-description' auto /
      4fr 1fr 2fr 5fr;
  }

  .c-points-showcase__description {
    margin-bottom: 64px;
  }
}

@media (min-width: 1312px) {
  .c-points-showcase__grid {
    grid-template:
      'why-samedi space claim-secure-icon claim-secure-text claim-secure-description' auto
      'why-samedi space claim-practical-icon claim-practical-text claim-practical-description' auto
      'why-samedi space claim-organized-icon claim-organized-text claim-organized-description' auto /
      4fr 1fr 1fr 2fr 4fr;
  }

  .c-points-showcase__description {
    margin-bottom: 80px;
  }
}
