.f-internet-explorer-user {
  grid-area: banner;
  display: block;
  position: relative;
  padding: 26px 16px 26px 16px;
  color: var(--grey-30);
  background-color: var(--beeswax-orange);
}

.f-internet-explorer-user__info-icon {
  align-self: center;
  margin-right: 8px;
  font-size: 38px;
}

.f-internet-explorer-user__header {
  font-size: var(--font-size-normal);
  line-height: var(--line-height-default);
  font-weight: 600;
}

.f-internet-explorer-user__content {
  font-size: var(--font-size-default);
}

.f-internet-explorer-user__browser-icon-group {
  display: flex;
  justify-content: flex-start;
  padding: 16px 0 0 48px;
}

.f-internet-explorer-user__browser-icon {
  margin-right: 24px;
  font-size: 40px;
  text-decoration: none;
}

.f-internet-explorer-user__browser-icon:last-child {
  margin-right: 0;
}

.f-internet_explorer_user__browser-text {
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  text-align: center;
}

@media (min-width: 768px) {
  .f-internet-explorer-user {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px 32px 16px 32px;
  }

  .f-internet-explorer-user__content {
    font-size: var(--font-size-default);
  }

  .f-internet-explorer-user__browser-icon-group {
    justify-content: flex-end;
  }
}

@media (min-width: 1024px) {
  .f-internet-explorer-user__browser-icon-group {
    padding: 0;
  }

  .f-internet-explorer-user__browser-icon {
    margin-right: 48px;
  }
}
