.c-video-consultation-notification__content {
  display: flex;
  margin-bottom: 16px;
  align-items: flex-start;
}

.c-video-consultation-notification__video-icon-container {
  position: relative;
  top: 3px;
  margin: 0 8px 8px 0;
  font-size: 20px;
}

.c-video-consultation-notification__message {
  width: 100%;
  margin-bottom: 16px;
  padding: 8px 16px;
  background-color: var(--mid-blue);
  color: #fff;
  border-radius: 3px;
  font-size: var(--font-size-default);
}

.c-video-consultation-notification__start-button {
  width: 100%;
}

.c-video-consultation-notification__start-button:hover {
  color: #fff;
  background-color: var(--sky-blue);
}

.c-video-consultation-notification__ongoing,
.c-video-consultation-notification__upcoming {
  display: flex;
  flex-direction: column;
  background-color: var(--mid-blue);
  box-sizing: border-box;
}

@media only screen and (min-width: 992px) {
  .c-video-consultation-notification__content {
    padding-right: 24px;
    margin-bottom: 0;
  }

  .c-video-consultation-notification__start-button {
    width: auto;
  }

  .c-video-consultation-notification__ongoing,
  .c-video-consultation-notification__upcoming {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}
