.o-vertical-separator {
  padding-top: 48px;
  padding-bottom: 48px;
}

@media (min-width: 768px) {
  .o-vertical-separator {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
