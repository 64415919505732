.c-video-consultation-how-it-works {
  padding: 16px 0;
}

.c-video-consultation-how-it-works__outer-container {
  display: flex;
  flex-direction: column;
}

.c-video-consultation-how-it-works__items-container {
  margin-bottom: 24px;
}

.c-video-consultation-how-it-works__item {
  color: var(--grey-30);
  padding-bottom: 32px;
}

.c-video-consultation-how-it-works__item {
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .c-video-consultation-how-it-works {
    padding: 24px 0 32px;
  }

  .c-video-consultation-how-it-works__outer-container {
    flex-direction: row;
  }

  .c-video-consultation-how-it-works__items-container {
    flex-basis: 66%;
    margin-right: 32px;
    margin-bottom: 0;
  }

  .c-video-consultation-how-it-works__item {
    padding-bottom: 24px;
  }
}
