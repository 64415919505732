.f-faq {
  border-color: var(--grey-95);
  border-style: solid;
  border-width: 1px 1px 0 1px;
  padding: 20px 16px;
}

.f-faq:first-child {
  border-radius: 8px 8px 0 0;
}

.f-faq:last-child {
  border-bottom-width: 1px;
  border-radius: 0 0 8px 8px;
}

.f-faq__question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey-30);
  cursor: pointer;
}

.f-faq__notch {
  color: var(--mid-blue);
  margin-left: 16px;
}

.f-faq__answer {
  padding-top: 32px;
  color: var(--grey-40);
}
