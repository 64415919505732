.c-current-user {
  z-index: 10;
  position: relative;
  box-sizing: border-box;
  border: solid 1px transparent;
}

.c-current-user.is-opened {
  height: 48px; /* height of the top menu bar (56px) - margin to the top (8px) */
  padding-bottom: 8px;
  border-color: var(--grey-90);
  background-color: var(--grey-96);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 3px 3px 0 0;
}

.c-current-user__user-info {
  padding: 0 16px;
  cursor: pointer;
  user-select: none;
}

.is-opened .c-current-user__user-info::after {
  content: '';
  height: 10px;
  background-color: var(--grey-96);
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  z-index: 15;
}

.c-current-user__label {
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  color: var(--grey-70);
  font-weight: bold;
}

.c-current-user__name {
  display: flex;
  align-items: center;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--mid-blue);
  font-weight: 600;
}

.c-current-user__user-info:hover .c-current-user__name {
  color: var(--sky-blue);
}

.c-current-user__menu {
  box-sizing: border-box;
  min-width: calc(100% + 2px);
  max-width: 400px;
  white-space: nowrap;
}

.is-opened .c-current-user__menu {
  position: absolute;
  top: 47px; /* height of div .c-current-user - 1px */
  right: -1px;
  border: 1px solid var(--grey-90);
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px 0 4px 4px;
}

.c-current-user__menu-item {
  box-sizing: border-box;
  padding: 8px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: auto;
}

.c-current-user__menu-icon {
  position: relative;
  bottom: 1px;
  vertical-align: middle;
  margin-right: 8px;
}

.c-current-user__menu-button {
  width: 135px;
}

.c-current-user__menu-spacer {
  width: 100%;
  height: 1px;
  background-color: var(--grey-95);
}

.c-current-user__menu-current-profile {
  background-color: var(--grey-96);
  color: var(--dark);
  border-bottom: 1px solid var(--grey-90);
}

.c-current-user__menu-item-wrapper {
  display: block;
  padding: 8px;
}

@media screen and (min-width: 768px) {
  .c-current-user__menu-item {
    min-width: 250px;
    max-width: 375px;
  }
}
