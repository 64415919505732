.o-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.o-menu__item {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 14px 0;
  cursor: pointer;
}

.o-menu__item--no-padding {
  padding: 0;
}

.o-menu__item--fixed-width {
  width: 32px;
}

.o-menu__item--fixed-width > div {
  margin: 0 auto;
}

.o-menu__content {
  display: none;
}

.o-menu__icon-container {
  display: inline-block;
  margin-right: 8px;
  width: 24px;
  text-align: center;
}
