.s-patient-events-modal__toggle-display {
  display: none;
}

.is-toggle-active .s-patient-events-modal__toggle-display {
  display: flex;
}

.s-patient-events-modal__content {
  border-radius: 12px;
  padding: 40px 24px 24px;
}
