.c-search-result-actions {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 16px;
}

@media (min-width: 768px) {
  .c-search-result-actions {
    flex-direction: row;
  }
}

.c-search-result-actions__button-group {
  flex-shrink: 0;
}

.c-search-result-actions__all-links {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .c-search-result-actions__all-links {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (min-width: 1200px) {
  .c-search-result-actions__secondary-links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 50%;
    height: 64px;
    white-space: nowrap;
  }
}

.c-search-result-actions__link {
  font-size: var(--font-size-small);
  line-height: var(--line-height-default);
}

@media (min-width: 768px) {
  .c-search-result-actions__link {
    margin-right: 8px;
  }
}

.c-search-result-actions__link a {
  word-break: break-word;
}

.c-search-result-actions__book-button {
  width: 100%;
  margin-bottom: 8px;
  text-align: center;
}

@media (min-width: 576px) {
  .c-search-result-actions__book-button {
    margin-right: 8px;
    margin-bottom: 0;
  }
}

.c-search-result-actions__book-button--adjusted-width {
  width: 100%;
}

@media (min-width: 576px) {
  .c-search-result-actions__book-button--adjusted-width {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .c-search-result-actions__book-button--adjusted-width {
    width: auto;
  }
}

.c-search-result-actions__more-info-button {
  width: 100%;
  text-align: center;
  margin-right: 0;
  margin-bottom: 0;
}

.c-search-result-actions__more-info-modal {
  display: none;
}

.is-toggle-active .c-search-result-actions__more-info-modal {
  display: flex;
}

.c-search-result-actions__more-info-modal-content {
  width: 100%;
}

.c-search-result-actions__modal-contact {
  display: flex;
  margin-bottom: 24px;
}

.c-search-result-actions__modal-contact a {
  word-break: break-word;
}

.c-search-result-actions__modal-contact-title {
  color: var(--grey-900);
}

.c-search-result-actions__modal-contact-icon {
  color: var(--grey-900);
  width: 24px;
  min-width: 24px;
}

.c-search-result-actions__modal-opening-hours {
  color: var(--grey-800);
}
