.t-patient-plus-banner__title {
  margin-bottom: 24px;
}

.t-patient-plus-banner__title b {
  font-weight: 600;
}

.f-patient-plus-banner__wrapper {
  display: grid;
}

.f-patient-plus-banner__advantage {
  display: flex;
  margin-bottom: 24px;
}

.f-patient-plus-banner__advantages-list {
  margin-top: 0;
}

@media (min-width: 768px) {
  .f-patient-plus-banner__wrapper {
    column-gap: 64px;
    grid-template-columns: 3fr 9fr;
  }
}

@media (min-width: 1200px) {
  .f-patient-plus-banner__wrapper {
    grid-template: none;
  }

  .f-patient-plus-banner__advantages-list {
    display: grid;
    column-gap: 16px;
    grid-template-columns: repeat(5, auto);
  }
}
