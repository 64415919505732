.o-switch {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 48px;
  height: 24px;
  padding: 0 3px;
  border-radius: 12px;
  background-color: var(--grey-96);
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.08);
}

.t-switch--small .o-switch {
  width: 32px;
  height: 16px;
  padding: 0 1px;
  border-radius: 8px;
}

.o-switch--is-active {
  background-color: var(--mid-blue);
  transition: background-color 0.3s;
}

.o-switch__handle {
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  transition: margin-left 0.3s;
}

.t-switch--small .o-switch__handle {
  width: 12px;
  height: 12px;
}

.o-switch--is-active .o-switch__handle {
  margin-left: 24px;
  transition: margin-left 0.3s;
}

.t-switch--small .o-switch--is-active .o-switch__handle {
  margin-left: 16px;
}

.o-switch__form-group {
  display: flex;
  align-items: center;
}

.t-switch--small .o-switch__switch-wrapper {
  margin-right: 8px;
}

.o-switch__switch-wrapper {
  margin-right: 16px;
}

.o-switch__switch-wrapper--has-note {
  align-self: flex-start;
  padding-top: 6px;
}

.o-switch__label {
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  color: var(--navy-blue);
  font-weight: 600;
}

.t-switch--small .o-switch__label {
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
}

.o-switch__label-note {
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  color: var(--grey-60);
  font-weight: normal;
}
