.o-select-box {
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--mid-blue);
  font-size: var(--font-size-default);
  font-weight: 300;
  color: var(--mid-blue);
  background: none;
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px auto;
  background-image: url('../images/chevron-down.svg');
  cursor: pointer;
}
.o-select-box::-ms-expand {
  display: none;
}
.o-select-box:focus {
  outline: none;
}

.error .o-select-box {
  color: var(--pink);
  border-bottom-color: var(--pink);
}

.o-select-box option {
  font-weight: normal;
}
